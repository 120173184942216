.opnstore__app-container{
    color: white;
}

/* Open store start selling section */

.opnstore__start-selling-container{
    background-color: #15151C;
    padding: 4rem 0;
}

.opnstore__start-selling-inner-container{
    display: flex;
    justify-content: center;
    height: 30vh;
    gap: 1rem;
    align-items: center;
}

.opnstore__odd-card{
    transform: skewX(-15deg);
    height: 18vh;
    background-color: #232230;
    padding: 1.5rem 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    transition: width .7s ease-in-out, height .7s ease-in-out;

}

.opnstore__odd-card-logo-overplace-container{
    display: flex;
    background-color: red;
}

.opnstore__odd-card-logo-overplace{
    /* transform: skewX(13deg); */
    background-color: #302F42;
    position: absolute;
    top: -20%;
    left: 20%;
    padding: 1rem 2rem;
    transition: top .7s ease-in-out, padding .7s ease-in-out;

    
}

.opnstore__odd-card-logo-backtrack{
    /* transform: skewX(25deg); */
}

.opnstore__odd-card-title{
    transform: skewX(20deg);
    font-size: 1.1rem;
    text-align: center;

}

.opnstore__odd-card-desc{
    transform: skewX(20deg);
    font-size: 1rem;
    color: #919198;
    text-align: center;
}

.opnstore__steps-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181620;
    gap: 1rem;
}

.opnstore__steps-first-layer{
    display: flex;
    /* gap: 1rem; */
    position: relative;
    width: 100%;
}

.opnstore__steps-second-layer{
    display: flex;
    gap: 1rem;
    width: 60%;
}

.opnstore__steps-item{
    grid: 1/span 2;
    background-color: #302F42;
    padding: 2rem 3rem;
    display: flex;
    height: 9rem;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.opnstore__step-slant{
    transform: skewX(-17deg);
}

.opnstore_step-divider{
    width: 1rem;
    height: 100%;
    left: 50%;
    transform: skewX(-17deg);
    position: absolute;
    background-color: #181620;
}

.opnstore__step-item-header{
    font-size: 1.1rem;
}

.opnstore__step-item-desc{
    font-size: 1rem;
    color: #9897A1;
    width: 20rem;
    text-align: center;
}
/* FAQ */

.opnstore__faq-sec{
    height: 50vh;
    background: linear-gradient(to bottom, #272134, #49355D);
}

.opnstore__faq-sec-inner{
    max-width: 90rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.opnstore__faq-qa{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.opnstore__faq-question{

}

.opnstore__faq-answer{
    width: 60%;
    color: #9B94A3;
}

/* Open a store */

.opn__open-store-modal-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.729);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: .7s fadeItIn ease-in-out;

}


.opn__open-store-modal{
    background-color: #232230;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: 3px solid #555155ac;
    outline-offset: 7px;
    /* width: 50vw; */
    position: relative;
    gap: 1rem;
    padding: 1rem 2rem;
    color: white;
    

}

.opn__close-btn-container{
    display: flex;
    position: absolute;
    right: 0%;
    top: -7%;
}

.opn__close-btn{
    width: 30px;
    height: 30px;
    padding: 1rem 1.5rem;
    transform: skewX(-15deg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #38383E;
    cursor: pointer;
}

.opn__close-btn-text{
    transform: skewX(15deg);

}

.opn__close-btn:active{
    transform: scale(1.05) skewX(-15deg);
}

.opn__open-store-modal-title{
    font-size: 2rem;
    color: white;
}

.opn__open-store-modal-info{
    color: #919198;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opn__open-store-form-group{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.opn__open-store-form-label{
    /* position: absolute; */
    padding: .3rem .5rem;
    background-color: #69166D;
    transform: skewX(-25deg);
    width: 60%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.opn__open-store-form-control-container{
    width: 100%;
    background-color: #15151C;
    border: .2rem solid #2B2B38;
    transform: skewX(-25deg);
    transition: .4s ease-in-out border;

}

.opn__open-store-form-control{
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 2rem 5rem;
    transform: skewX(25deg);
    color: white;

}

.opn__open-store-form-control:focus{
    outline: none;
}

.opn__open-store-response-info{
    font-size: .9rem;
    color: #BCBCBC;
}


.opn__open-store-btn-group{
    display: flex;
    justify-content: center;
    width: 100%;
}


.opn__open-store-btn{
    transform: skewX(-25deg);
    padding: 1rem 2rem;
    width: 90%;
    border: none;
    background-color: #FF9700;
    outline: 3px solid #FFC166;
    outline-offset: -5px;
    color: white;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 900;

}

.opn__open-store-btn:active{
    transform: skewX(-25deg) scale(1.05);

}

.opn__open-store-btn-text{
    transform: skewX(25deg);

}
.opn__open-store-terms-container{
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
}
.form-alert-container{
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    padding: .9rem;
    background-color: #ffffff;
    height: 3rem;
    border-top-left-radius: 200%;
    border-bottom-left-radius: 200%;
}
.triangle {
    width: 0;
    height: 0;
    background-color: #ffffff;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-right: 50px solid transparent;
  }