/* NEW MEDIA QUERIES BREAK POINT */

@media screen and (min-width: 1501px) {
  /* .buy_socials-container{
       
        margin-left: 6rem;
    
    } */
  .landing__card {
    height: 34vh;
  }
}

/* Desktops and large screens */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  /* .buy_socials-container{
        
        margin-left: 6rem;
    
    } */

  .user__freebies-inner-container-inner {
    grid-template-columns: repeat(3, 1fr);
  }

  html {
    font-size: 72.5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* .buy_socials-container{
      
        margin-left: 6rem;
    
    } */

  .user__freebies-inner-container-inner {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .user__freebies-container {
    /* padding: 4rem 1rem; */
    background-color: #15151c;
    height: 80vh;
    overflow-y: auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: 35% 65%;
  }

  html {
    font-size: 52.5%;
  }

  .landing__card {
    height: 36vh;
  }
}

.landing__freebies-container {
  background-color: #1b1a24;
  padding: 2rem 0;
}

/* Ipads and Tablets */
@media screen and (min-width: 481px) and (max-width: 768px) {
  /* .buy_socials-container{
       
        margin-left: 6rem;
    
    } */

  .user__admin-page-section-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .user__freebies-inner-container-inner {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .landing__freebies-inner-container {
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 2rem;
  }
  .landing__slogan {
    justify-content: center;
  }

  .landing__card {
    height: 36vh;
  }
  .landing__all-products-inner-container {
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem;
  }

  .landing__double-img-grey {
    width: 50%;
  }
  .landing__double-img-normal {
    width: 50%;
  }
  .landing__open-img {
    left: 0;
  }
  .landing__partner-img {
    right: 0;
    clip-path: none;
  }
  .landing__img-overlay-open {
    left: 0;
  }
  .landing__img-overlay-partner {
    right: 0;
    clip-path: none;
  }
  .landing__open-partner-label-container {
    width: 90%;
    left: 10%;
  }
  .landing__bg-label-open {
    clip-path: none;
  }
  .landing__bg-label-partner {
    padding-right: 8%;
  }
  .landing__partner-label-desc {
    width: 60%;
  }
  .landing__footer {
    height: 25vh;
  }
  .cart__inner-container {
    flex-direction: column;
    width: 98% !important;
    margin: 2rem auto;
  }
  .cart__items-container {
    height: auto;
  }
  .cart__category-container {
    width: 100%;
  }
  .cart__cart-items-container {
    width: 100%;
    max-width: 100%;
    padding: 0.4rem 0.6rem !important;
  }
  .cart__cart-item-outer {
    gap: 1rem !important;
  }
  .cart__cart-item {
    width: 98% !important;
    gap: 1rem !important;
    justify-content: unset;
    padding: 0.5rem 1rem !important;
  }
  .cart__item-price-btn {
    width: 7%;
    margin-left: 0.5rem;
    display: none !important;
  }
  .cart__item-mobile-price-btn {
    display: flex !important;
    width: 4%;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
  .cart__category-container {
    gap: 7rem !important;
    padding-left: 0 !important;
  }
  .cart__item-price-close-btn {
    font-size: 1.5rem !important;
    padding: 0.3rem !important;
    position: unset !important;
  }
  .cart__item-title {
    padding-left: 0 !important;
  }
  .cart__item-license-type {
    padding: 0 !important;
    padding-top: 1rem !important;
    border-left: none;
    border-right: none;
  }
  .cart__cat-item {
    padding: 0.7rem 4rem;
  }
  .cart_item-mobile-description-box {
    width: 50%;
    padding: 1rem 0.6rem;
    align-items: center;
    justify-content: space-around;
  }
  .cart__subtotal-container {
    gap: 17%;
  }
  .cart__payment-type {
    display: none !important;
  }
  .cart__payment-type-mobile {
    display: flex !important;
    gap: 1.5rem;
    margin: 2rem;
  }

  .user__library-sidebar {
    display: none;
  }

  /* edit from mobile*/
  .user__main-profile-form {
    gap: 0.3rem;
  }
  .user__main-profile-form-group {
    flex-direction: column;
    width: 80%;
    margin: 0.5rem auto;
    gap: 0.4rem;
  }

  .user__label-container {
    width: fit-content;
    margin: 0 auto;
  }
  .user__main-profile-label {
    align-self: center;
    text-align: center;
    background-color: transparent;
    justify-content: center;
  }
  .user__main-profile-label-inner {
    text-align: center;
  }
  .user__sales {
    display: flex;
  }
  .user__sales-balance-first-row {
    flex-direction: column;
    width: 98%;
    margin: auto;
  }
  .user__sales-statistics-box-header {
    flex-direction: column;
  }
  .user__sales-header-text {
    padding: 1rem;
  }
  .user__sales-sidebar {
    display: none;
  }
  .user__sales-balance-second-row {
    display: flex;
    flex-direction: column;
  }
  .user__sales-next-payout-text {
    background-color: transparent;
    color: #8a8a91;
  }
  .admin__home-ra-header {
    color: #484854;
  }
  .admin__home-ra-cr-header-field.sales {
    display: none;
  }
  .admin__home-ra-cr-field.sales {
    width: 50%;
    padding: 1.5rem 1rem;
  }
  .user__sales-next-payouts {
    margin-top: 4rem;
    gap: 2rem;
    width: 80%;
    margin: 3rem auto;
  }
  .user__sales-next-payout {
    margin-top: 10rem;
  }

  .user__sales-balance-announcer-container {
    width: 90%;
    margin: 2rem auto;
    gap: 2rem;
  }
  .user__sales-payout-withdraw-btn {
    justify-content: center;
  }
  .user__sales-mc-header {
    display: none;
  }
  .user__sales-balance-ann-card-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .user__sales-mc-item-container {
    flex-direction: column;
    gap: 2rem;
  }
  .user__sales-mc-item-header {
    background-color: transparent;
  }
  .user__sales-mc-item-body {
    width: 90%;
    margin: auto;
  }
  .user__sales-mc-dropdown {
    width: 100%;
  }
  .user__sales-mc-item-container.button {
    padding-left: unset;
    margin: 0 auto 4rem 60%;
  }
  .user__inpage-mobile-nav-header {
    margin-top: 1rem;
  }
  .user__settings-sidebar {
    display: none;
  }
  .account-sett-body,
  .user__store-sett-body {
    padding-left: unset;
    padding: 4rem 2rem;
  }
  .user__sales-mc-input {
    width: 100%;
  }
  .mobile-universal-header-dropdown-item-container,
  .mobile-inpage-header-dropdown-item-container {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    z-index: 20;
  }
  .mobile-inpage-header-dropdown-item-container {
    top: 8rem;
    background-color: #15151c;
  }
  /* upload */

  .upload__mock-up {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .upload__description-section {
    width: 80%;
    margin: 0 auto 6rem auto;
  }

  .upload__image-preview {
    width: 65vw;
  }

  @media screen and (max-width: 768px) {
    .upload__image-preview {
      width: 100%;
    }
  }

  .upload__tags-container {
    margin-top: 2rem;
  }
  .upload__tags-inner-container {
    width: 95%;
    margin: 1rem auto;
  }
  .upload__price-inner-section {
    flex-direction: column;
    gap: 1.5rem;
  }
  .upload__form-group-extra {
    padding: 1rem 2rem;
  }
  .upload__license-text {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .upload__draft-btn,
  .upload__publish-btn {
    transform: skewX(-25deg);
  }
  .user__store-products-header {
    display: none !important;
  }
  .user__store-freebies-container {
    padding-top: 1rem;
  }
  .subscription-btn {
    right: unset;
  }
  .buy_socials-container {
    width: 80%;
    margin: 0 auto;
  }
  .landing__sidebar-toggle-close {
    left: 86%;
  }
  .user__sales-skew-container {
    width: 100%;
  }
  .user__main-profile-info {
    display: none;
  }
  .account-sett-input-label {
    font-size: 1.4rem;
  }
  .privacy_policy_container {
    width: 100%;
    padding: 4rem 4rem;
  }
  .reg__loooty-innitials-container {
    display: none !important;
  }
  .opnstore__start-selling-inner-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: auto;
  }
  .cart__items-container {
    width: 100% !important;
  }
  .cart__payment-gateway-container {
    width: 100% !important;
  }
}

/* Mobile devices */
/* small mobile phone 320px  -  480px */
@media screen and (min-width: 0px) and (max-width: 480px) {
  html {
    font-size: 60%;
  }
  .landing__footer {
    height: auto;
    /* transform: scale(.6)!important; */
    zoom: 0.5;
  }
  .landing__freebies-inner-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .landing__footer-inner {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    padding-left: 1rem;
  }
  .landing__footer-socials {
    align-items: flex-start;
  }
  .landing__post-container {
    height: auto;
    zoom: 0.5;
  }
  .landing__navbar-mobileView {
    /* justify-content: unset; */
    z-index: 999;
  }
  .search__nav-search-btn {
    position: absolute;
    right: 0;
    height: 96%;
    padding-top: 1rem;
  }
  .navbar-mobile-filter-search-container {
    display: flex;
  }

  .landing__navbar-mobile-search-box,
  .landing__navbar-mobile-filter-box {
    height: 3vh;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    margin-top: 1rem;
    background-color: #15151c;
  }
  .landing__navbar-mobile-filter-box {
    gap: 0.3rem;
    margin-top: 2rem;
    width: 98%;
    margin: 1.5rem auto;
  }

  .landing__double-inner-container {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .landing__freebies-inner-container {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    margin: 0 auto;
    gap: 3rem;
  }
  .landing__all-products-inner-container {
    grid-template-columns: repeat(1, 1fr);
    min-height: 100vh;
    padding: 2rem 3rem;
  }

  .landing__slogan {
    text-wrap: nowrap;
    justify-content: center;
  }
  .landing__slogan-first-text {
    font-size: 1.4rem;
  }
  .landing__slogan-second-text {
    font-size: 2.5rem;
  }

  .search__skew-container {
    zoom: 0.7;
  }

  .landing__cg-tutorials {
    zoom: 0.5;
  }

  .landing__cg-card-container-slim {
    width: 100%;
    top: 5%;
    zoom: 2;
    overflow-x: scroll;
    display: none;
  }

  .landing__show-filters-container {
    zoom: 0.6;
    height: 12vh;
    padding-top: 2rem;
  }

  .landing__search-section {
    height: 5vh;
    max-width: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .landing__filter-tags-btn-text {
    font-size: 0.7rem;
  }

  .landing__start-selling-text {
    font-size: 1.5rem;
  }

  .landing__start-selling-desc {
    font-size: 0.6rem;
    margin-bottom: 1.5rem;
  }
  .landing__slogan-browse-text,
  .landing__slogan-sell-text {
    font-size: 0.9rem;
  }
  .landing__open-partner-container {
    zoom: 0.3;
  }

  .landing__header {
    height: 70vh;
  }

  .landing__instruct-btn-container {
    flex-direction: column;
  }

  .landing__open-partner-label-container {
    width: 70%;
  }

  .landing__weekly-freebies-container {
    padding-left: 2.5rem;
  }

  .landing__show-filters-container {
    height: auto;
    max-width: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .landing__filter-tag-container {
    height: auto;
    max-width: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .landing__all-products-container {
    padding: 1rem;
  }
  .landing__double-img-normal {
    width: 50%;
    height: 25vh;
    object-fit: cover;
  }

  .landing__double-img-grey {
    width: 50%;
    height: 25vh;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .landing__daily-picks-filters-container {
    zoom: 1;
    overflow-x: scroll;
  }

  .landing__awesome-arts-sec {
    height: 5vh;
    max-width: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .landing__post-container {
    flex-direction: column;
    gap: 2rem;
  }
  .landing__footer {
    height: auto;
  }

  /*preview */

  .buy__buy-preview-container-inner {
    flex-direction: column;
    gap: 2rem !important;
  }

  .buy__carousel-container {
    width: 100% !important;
  }

  /* modals */

  .reg__sign-up-overlay {
    padding: 0.8rem;
    position: absolute;
    z-index: 99 !important;
  }

  .reg__sign-up-modal {
    width: 80% !important;
    zoom: 0.7;
  }

  .user__withdrawal-modal {
    width: 70% !important;
  }

  .reg__btn-google,
  .reg__btn-facebook {
    font-size: 0.9rem;
  }

  /* upload */

  .upload__mock-up {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .upload__title-section {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 1rem;
    width: 100%;
  }
  .upload__main-content {
    width: 100%;
  }

  .upload__description-section {
    width: 100%;
  }

  .upload__publish-section {
    zoom: 0.9;
    flex-direction: column;
    gap: 2rem;
    margin: 1rem auto;
    transform: none;
  }
  .upload__title {
    background-color: transparent;
  }

  .user__profile-caution {
    width: 80%;
  }

  .user__product-action-container {
    zoom: 0.7;
  }

  .user__upload-products-btn {
    width: 40%;
  }

  .user__admin-visitor-container {
    right: 5%;
    top: -40%;
  }

  .user__main {
    width: 100%;
  }

  .user__admin-page-section-header-container {
    zoom: 1;
    overflow-x: scroll;
    padding-left: 20rem;

    /* width: 50vw; */
    /* background-color: red; */
  }

  .user__admin-page-section-header-container::-webkit-scrollbar {
    display: none;
  }

  .user__main-lib-body {
    grid-template-columns: repeat(2, 1fr);
  }

  .user__library-inner {
    flex-direction: column;
  }

  .landing__card-userStore {
  width: auto;
  }
  
  .user__main-lib-body-collections {
    grid-template-columns: repeat(1, 1fr);
  }

  .user__main-sett-body {
    flex-direction: column;
  }

  .user__settings-sidebar-body {
    padding: 1rem 1rem;
  }

  .user__settings-item {
    width: 100%;
  }

  .user__message-friend {
    width: 100%;
  }

  .user__freebies-inner-container-inner {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .buy__comment-description {
    width: 90% !important;
  }
  .cart__inner-container {
    flex-direction: column;
    width: 100%;
  }
  .cart__items-container {
    height: auto;
  }
  .cart__category-container {
    width: 100%;
  }
  .cart__cart-items-container {
    width: 100%;
    max-width: 100%;
    padding: 0.4rem 0.6rem !important;
  }
  .cart__cart-item-outer {
    gap: 1rem !important;
  }
  .cart__cart-item {
    width: 98% !important;
    gap: 1rem !important;
    justify-content: unset;
    padding: 0.5rem 1rem !important;
  }
  .cart__item-price-btn {
    width: 7%;
    margin-left: 0.5rem;
    display: none !important;
  }
  .cart__item-mobile-price-btn {
    display: flex !important;
    width: 4%;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
  .cart__category-container {
    gap: unset !important;
    padding-left: 0 !important;
  }
  .cart__item-price-close-btn {
    font-size: 1.5rem !important;
    padding: 0.3rem !important;
    position: unset !important;
    /* right: -2% ; */
  }
  .cart__item-title {
    padding-left: 0 !important;
  }
  .cart__item-license-type {
    padding: 0 !important;
    padding-top: 1rem !important;
    border-left: none;
    border-right: none;
  }
  .cart__cat-item {
    padding: 0.7rem 4rem;
  }
  .cart_item-mobile-description-box {
    width: 80%;
    padding: 1rem 0.6rem;
  }
  .cart__subtotal-container {
    gap: 17%;
  }
  .cart__payment-type {
    display: none !important;
  }
  .cart__payment-type-mobile {
    display: flex !important;
    flex-direction: column;
    gap: 1.5rem;
    margin: 2rem;
  }

  .cart__card-insertion-container {
    display: none !important;
  }
  .user__main-profile-form-control-container-social {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .user__store-freebies-inner-container,
  .users__suscribers-container-inner {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .user__no-product-div {
    width: 50%;
    margin: 5rem auto;
  }

  .user__library-sidebar {
    display: none;
  }
  .user__main-profile-form {
    gap: 0.3rem;
  }
  .user__main-profile-form-group {
    flex-direction: column;
    width: 80%;
    margin: 0.5rem auto;
    gap: 0.4rem;
  }

  .user__label-container {
    width: fit-content;
    margin: 0 auto;
  }
  .user__main-profile-label {
    align-self: center;
    text-align: center;
    background-color: transparent;
    justify-content: center;
  }
  .user__main-profile-label-inner {
    text-align: center;
  }
  .user__sales {
    display: flex;
  }
  .user__sales-balance-first-row {
    flex-direction: column;
    width: 98%;
    margin: auto;
  }
  .user__sales-statistics-box-header {
    flex-direction: column;
  }
  .user__sales-header-text {
    padding: 1rem;
  }
  .user__sales-sidebar {
    display: none;
  }
  .user__sales-balance-second-row {
    display: flex;
    flex-direction: column;
    margin-top: 15rem;
  }
  .user__sales-next-payout-text {
    background-color: transparent;
    color: #8a8a91;
  }
  .admin__home-ra-header {
    color: #484854;
  }
  .admin__home-ra-cr-header-field.sales {
    display: none;
  }
  .admin__home-ra-cr-field.sales {
    width: 50%;
    padding: 1.5rem 1rem;
  }
  .user__sales-next-payouts {
    margin-top: 4rem;
    gap: 2rem;
    width: 80%;
    margin: 3rem auto;
  }
  .user__sales-balance-announcer-container {
    width: 90%;
    margin: 2rem auto;
    gap: 2rem;
  }
  .user__sales-payout-withdraw-btn {
    justify-content: center;
  }
  .user__sales-mc-header {
    display: none;
  }
  .user__sales-balance-ann-card-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .user__sales-mc-item-container {
    flex-direction: column;
    gap: 2rem;
  }
  .user__sales-mc-item-header {
    background-color: transparent;
  }
  .user__sales-mc-item-body {
    width: 90%;
    margin: auto;
  }
  .user__sales-mc-dropdown {
    width: 100%;
  }
  .user__sales-mc-item-container.button {
    padding-left: unset;
    margin: 0 auto 4rem 60%;
  }
  .user__inpage-mobile-nav-header {
    margin-top: 1rem;
  }
  .user__settings-sidebar {
    display: none;
  }
  .account-sett-body,
  .user__store-sett-body {
    padding-left: unset;
    padding: 4rem 2rem;
  }
  .user__sales-mc-input {
    width: 100%;
  }
  .mobile-universal-header-dropdown-item-container,
  .mobile-inpage-header-dropdown-item-container {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    z-index: 20;
  }
  .mobile-inpage-header-dropdown-item-container {
    top: 8rem;
    background-color: #15151c;
  }
  .upload__tags-container {
    margin-top: 2rem;
  }
  .upload__tags-inner-container {
    width: 95%;
    margin: 1rem auto;
  }
  .upload__price-inner-section {
    flex-direction: column;
    gap: 1.5rem;
  }

  .upload__license-text {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .upload__draft-btn,
  .upload__publish-btn {
    transform: skewX(-25deg);
  }
  .user__store-products-header {
    display: none !important;
  }
  .user__store-freebies-container {
    padding-top: 1rem;
  }
  .subscription-btn {
    right: unset;
  }
  .buy_socials-container {
    width: 80%;
    margin: 0 auto;
  }
  .landing__sidebar-toggle-close {
    left: 86%;
  }
  .user__sales-skew-container {
    width: 100%;
  }
  .user__main-profile-info {
    display: none;
  }
  .account-sett-input-label {
    font-size: 1.4rem;
  }
  .privacy_policy_container {
    width: 100%;
    padding: 4rem 4rem;
  }
  .reg__loooty-innitials-container {
    display: none !important;
  }
  .upload_main-content-category-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .opnstore__start-selling-inner-container {
    flex-direction: column;
    height: auto;
    gap: 6rem;
  }
  .opnstore__steps-item {
    width: 48%;
  }
  .opnstore__step-slant {
    width: 100%;
    margin: auto;
  }
  .opnstore__step-item-desc {
    width: 100%;
  }
  .opnstore__faq-sec {
    padding-left: 2rem;
  }
  .cart__items-container {
    width: 100% !important;
  }
  .cart__payment-gateway-container {
    width: 100% !important;
  }
}

.admin__catalogues {
  display: flex;
  color: #8a8a91;
  padding: 1rem 1rem 0;
  gap: 2rem;
  text-transform: capitalize;
  border-bottom: 4px solid #3e3e3f80;
}

.admin__catalogues-inner {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.admin__catalogues p {
  padding: 0.5rem 0.5rem 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.admin__catalogues .active {
  background-color: rgba(0, 0, 0, 0.3);
}
.admin__catalogues .active::before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;
  height: 4px;
  background-color: #7c187a;
}
.catalogue__titleInput {
  background-color: transparent;
  color: white;
  padding: 1rem;

  border: 0.2rem solid #2c2c3a;
  width: 100%;
}
.catalogue__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
}

.catalogue__products {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-height: 150px;
  overflow-y: scroll;
}

.catalogue__products div {
  width: 60px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.catalogue__products div:hover {
  box-shadow: 1px 2px 3px #15151c;
}

.catalogue__products p {
  font-size: 12px;
  padding: 0.2rem;
}

.catalogue__selected {
  position: absolute;
  top: 2px;
  right: 2px;
}
.no-display {
  display: none;
}
