.cart {
  display: flex;
  margin-top: 2rem;
}
.stripe-checkout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  background-color: #eee;
  height: 100vh;
  z-index: 999;
}
#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

.cart__inner-container {
  /* max-width: 100rem;
    margin: 0 auto; */
  display: flex;
  gap: 3rem;
  width: 100%;
}

.cart__items-container {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  background-color: #15151c;
  width: 60%;
  height: 100vh;
  flex: 2;
}

.cart__category-container {
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  border-bottom: 0.2rem solid #2a2a38;
  padding-bottom: 0.2rem;
  padding-left: 2rem;
}

.cart__cart-items-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem 7rem;
}

.cart__cat-item {
  display: flex;
  gap: 1rem;
  border-right: 0.1rem solid #2a2a38;
  color: white;
  padding: 0.7rem 5rem;
  font-size: 0.8rem;
}

.cart__cat-item.borded {
  border-right: 0.1rem solid #2a2a38;
  border-left: 0.1rem solid #2a2a38;
  padding: 0.7rem 4.5rem;
}

.cart__cat-item.product {
  border-right: 0.1rem solid transparent;
  border-left: 0.1rem solid transparent;
}
.cart__cart-item-outer {
  display: flex;
  align-items: center;
  gap: 3rem;
  /* overflow-x: scroll; */
}

.cart__cart-item {
  display: flex;
  gap: 2rem;
  color: white;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #232230;
  padding: 0.7rem;
  width: 90%;
}

.cart__item-title {
  font-size: 1.2rem;
  color: #d2d7db;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 1rem;
  flex-wrap: nowrap;
}

.cart__item-license-type {
  color: #636384;
  border-left: 0.1rem solid #2a2a38;

  border-right: 0.1rem solid #2a2a38;
  height: 100%;

  padding: 1rem 2rem;

  font-size: 0.9rem;
}

.cart__item-price-btn,
.cart__item-mobile-price-btn {
  transform: skewX(-20deg);
  padding: 0.4rem 3rem;
  background-color: #353544;
  border: transparent;
  margin-right: 1rem;
  margin-left: 2rem;
  color: #d2d7db;
  display: flex;
  font-size: 1.2rem;
}

.cart__item-mobile-price-btn {
  display: none;
}

.cart__item-price-close-btn {
  position: absolute;
  right: -5.4%;
  transform: skewX(-20deg);
  padding: 0.3rem 0.8rem;
  background-color: #38383e;
  border: transparent;
  color: #ffffff;
  display: flex;
  font-size: 2rem;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.cart__item-price-close-btn-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart__item-price-close-btn:active {
  transform: scale(1.05) skewX(-20deg);
}

.cart__cart-item-builder-close {
  transform: skewX(20deg);
  background-color: #38383e;
  padding: 0.3rem 1rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: -17%;
}

.cart__cart-item-border-right {
  border-right: 0.2rem solid #2a2a38;
}

.cart__item-img {
  width: 7rem;
  height: 4rem;
  object-fit: cover;
  flex: 3;
}

.cart__trash {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #232230;
  color: #7b7a83;
  width: 200px;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  cursor: pointer;
}

/* Gateway section */

.cart__payment-gateway-container {
  width: 100%;
  flex: 1;
  /* padding-left: 5rem; */
  display: flex;
  justify-content: flex-start;
}

.cart__gateway-options-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
}

.cart__payment-type {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
}

.cart__payment-type-mobile {
  display: none;
}

.cart__payment-type-btn {
  background-color: #37364c;
  width: 40%;
  /* padding: 1rem 2rem; */
  height: 3.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.payment-option-btn {
  gap: 1rem;
  padding: 1rem 3rem;
}

.payment-option-btn span {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.cart_payment-option-logo {
  font-size: 2rem;
  transform: skewX(25deg);
}

.cart__payment-type-btn-selected {
  border: 0.2rem solid #a3a3a3;
  color: white;
}

.cart__payment-type-btn-selected-mobile {
  border: 7px solid #818181;
  opacity: 1 !important;
}

.cart__card-insertion-container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.cart__card-insertion-container-mobile {
  margin: 2rem;
}

.cart_info_container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.cart__form-group {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.cart__form-double-group {
  display: flex;
  gap: 0.6rem;
}

.cart__form-control-skew {
  transform: skewX(-20deg);
  display: flex;
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
  position: relative;
}

.cart__form-control {
  padding: 1rem 3rem;
  transform: skewX(20deg);
  background-color: transparent;
  border: none;
  width: 100%;
  color: #515170;
}

.cart__form-card-logo {
  position: absolute;
  right: 5%;
  top: 30%;
  transform: skewX(20deg);
  color: #515170;
  font-size: 1.3rem;
}

.cart__form-label {
  color: #3e3e4f;
}

.cart__form-control:focus {
  outline: none;
}

.cart__form-select-skew {
  transform: skewX(-20deg);
  background-color: #2b2b38;
  position: relative;
  border: 0.2rem solid #15151c;
  cursor: pointer;
}

.cart__select-preview {
  transform: skewX(20deg);
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: center;
  color: #515170;
}

.cart__form-arrow {
  position: absolute;
  right: 8%;
  top: 40%;
  transform: rotate(180deg);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.cart__country-select {
  position: absolute;
  bottom: -250%;
  left: 3%;
  padding: 2rem 1rem;
  width: 100%;
  background-color: #353544;
  transform: skewX(20deg);
  color: #d2d7db;
}

.cart__payment-or {
  color: #4b4b68;
  text-align: center;
}

.cart__other-payment-options-container {
  background-color: #37364c;
  padding: 1rem 2rem;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cart-other-pay-inner-container {
  display: flex;
  gap: 1rem;
}

.cart__other-crypto-btn {
  background-color: #424a5b;
  outline: 2px solid #8e929d;
  outline-offset: -0.4rem;
  padding: 1rem 1.5rem;
  border: none;
  color: white;
  font-size: 0.8rem;
}

.cart__other-paypal-btn {
  background-color: #34b6ea;
  outline: 2px solid #85d3f2;

  outline-offset: -0.4rem;
  padding: 1rem 1.5rem;
  font-size: 0.8rem;

  border: none;
  color: white;
}

.cart__pay-caution {
  font-size: 0.7rem;
  text-align: center;
  color: #6d6d91;
}

.cart__calc-container {
  color: white;
  margin-top: 3rem;
}

.cart__subtotal-container {
  display: flex;
  justify-content: center;
  gap: 37.5%;
}

.cart__subtotal-title {
  font-size: 2rem;
}

.cart__subtotal-title-price {
  font-size: 2rem;
  border-left: 0.2rem #2b2b38 solid;
  padding-left: 4rem;
}
.phone-input-custom {
  background-color: rgb(21, 21, 28);
  padding: 1rem 2rem;
  transform: skewX(-20deg);
  border: 0.2rem solid #2a2a37;
}

.PhoneInputCountry {
  margin-right: 5px;
}

.PhoneInputCountrySelect {
  background-color: #353544;
  color: #fff;
}

.PhoneInputCountrySelect option {
  padding: 1rem;
}

.PhoneInputCountrySelectArrow {
  color: #fff;
  font-size: 16px;
  margin: 0 5px;
}

.PhoneInputInput {
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  padding-left: 0.7rem;
}
.cart__payBtn {
  width: 100%;
  padding: 1rem 2rem;
  background-color: #5cb85c;
  color: #ffffff;
  transform: skewX(-20deg);
  outline: 2px solid #9dd49d;
  outline-offset: -6px;
  border: none;
  cursor: pointer;
}
