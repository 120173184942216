.user__mobile-page-section-header-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.user__mobile-page-section-header-item-selected {
  padding: 1rem 2rem;
  width: 95%;
  margin: 0 auto 1rem auto;
  border: 0.4px solid #6d6d91;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
}

.user__mobile-page-section-header-item {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #6d6d91;
  border-bottom: 0.4px solid #6d6d91;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 3rem;
  position: relative;
  background-color: #232230;
}

.user__inpage-mobile-nav-header {
  background-color: transparent;
}

.user__mobile-page-section-header-arrow-down,
.nav__mobile-filter-section-sub-option-arrow-down {
  font-size: 2.5rem;
  color: #6d6d91;
  position: absolute;
  right: 0;
}
.nav__mobile-filter-section-sub-option-arrow-down {
  right: 2rem;
}

.user__profile-preview-container {
  background-image: url('/public/assets/image/background/empty-store.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: repeat;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 2rem;
  position: relative;
}

.user__profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.user__profile-container.admin {
  flex-direction: row;
}

.admin__profile-container {
  flex-direction: column;
}

.user__profile-container-admin {
  display: flex;
  flex-direction: column;
}

.user__profile-img-box {
  height: 7rem;
  width: 7rem;
  position: relative;
  display: inline-block;
}

.user__profile-img {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 100%;
  display: block;
}

.user__profile-img-change-box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 45%;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom-left-radius: 50% 100%;
  border-bottom-right-radius: 50% 100%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.user__profile-img-box:hover .user__profile-img-change-box {
  display: flex;
}

.user__profile-img.admin {
  height: 5rem;
  width: 5rem;
}

.user__profile-name {
  font-size: 1.6rem;
  color: white;
  text-transform: capitalize;
}

.user__profile-suscribers {
  color: #8c92a3;
  font-size: 0.9rem;
}

.user__profile-caution {
  color: #8c92a3;
  width: 20%;
  text-align: center;
}

.user__location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.user__change-cover-btn {
  background-color: rgba(255, 255, 255, 0.253);
  padding: 0.7rem 1.2rem;
  border: none;
  position: absolute;
  right: 5%;
  top: 80%;
  transform: skewX(-20deg);
  color: #a5b6c3;
  gap: 0.4rem;
}

.subscription-btn {
  position: absolute;
  right: 5%;
  top: 80%;
}

.user__profile-switch-admin-btn {
  background-color: #ff9700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bolder;
  position: absolute;
  width: 100%;
  top: 0%;
  padding: 0.5rem;
  cursor: pointer;
}

.user__profile-switch-admin-btn:active {
  transform: scale(1.05);
}

.user__change-cover-btn-inner {
  transform: skewX(20deg);
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  align-items: center;
  cursor: pointer;
}

.user__product-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 10vh;
  gap: 2rem;
}

.user__upload-products-btn {
  transform: skewX(-20deg);
  padding: 1rem 2rem;
  border: none;
  outline: 0.2rem solid #cf7bcf;
  background-color: #af23af;
  outline-offset: -0.4rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 15vw;
}

.user__upload-products-btn:active {
  transform: scale(1.1) skewX(-20deg);
}

.user__upload-products-btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transform: skewX(20deg);
  font-size: 0.85rem;
  font-weight: 600;
}

.user__admin-visitor-container {
  position: absolute;
  right: 5%;
  top: 30%;
  display: flex;
  background-color: #15151c;
  padding: 0.2rem;
  transform: skewX(-20deg);
  border: 0.2rem solid #2b2b38;
}

.user__AV-btn {
  padding: 0.3rem 0.8rem;
  background-color: #15151c;
  color: white;
  display: flex;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.user__AV-btn:active {
  transform: scale(1.05);
}

.user__AV-btn-inner {
  transform: skewX(20deg);
}

.user_AV-btn-active {
  background-color: #38383e !important;
  color: white;
  /* padding: .5rem 1rem; */
}

.user_AV-btn-active-dup {
  background-color: #ff9700;
  color: white;
}

.user__admin-page-section-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* padding-right: 20rem; */
  /* background-color: red; */
}

.user__admin-page-section-header-container-admin {
  overflow-x: auto;
  width: 100%;
  padding: 0 2rem;
  padding-top: 1.5rem;
  /* padding-left: 30vw; */
}

.user__admin-page-section-header-container-admin-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.user__sidebar-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
}

.user__sidebar-info-field {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 15%;
  border-bottom: 0.2rem solid #2a2a37;
  padding-bottom: 1rem;
}

.user__sidebar-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.user__sidebar-info-field-right {
  color: #5f5f74;
}

.user_tags-filter-selector-container {
  background-color: transparent;
  color: white;
  transform: skewX(-25deg);
  border: 0.2rem solid #2b2b38;
}

.user__store-freebies-container {
  background-color: #1b1a24;
  height: 100%;
  padding: 4rem 0;
}

.user__store-freebies-inner-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  max-width: 90rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  min-height: 20vh;
}

.user__no-product-div {
  color: white;
  width: fit-content;
  min-width: 300px;
  text-align: center;
  height: fit-content;
  padding: 1rem;
  border: 0.2rem dashed;
}

.user_tags-filter-selector-container-inner {
  transform: skewX(25deg);
  display: flex;
  gap: 1rem;
}

.user__filter-clear-btn {
  transform: skewX(-25deg);
  display: flex;
  padding: 0.4rem 0.9rem;
  border: none;
  cursor: pointer;
  background-color: #38383e;
  outline: 2px solid #2b2b38;
  outline-offset: 3px;
}

.user__filter-clear-btn:active {
  transform: skewX(-25deg) scale(1.1);
}

.user__filter-clear-btn span {
  transform: skewX(25deg);
  color: #d2d7db;
}

.user__sidebar-rating-container {
  display: flex;
  gap: 1rem;
}

.user__main-rating-container {
  display: flex;
  gap: 0.3rem;
}
.user__sidebar-info-field-left {
  background-color: #44434f;
  color: #5f5f74;
  padding: 0.6rem;
  width: 50%;
}

.user__freebies-container {
  /* padding: 4rem 1rem; */
  background-color: #15151c;
  height: 80vh;
  /* overflow-y: auto; */
  display: grid;
  /* gap: 1rem; */
  grid-template-columns: 23% 75%;
}
.user__freebies-sidebar {
  height: 100%;
  background-color: #232230;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* padding: 1rem; */
}

.user__freebies-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 3rem;
  width: 100%;
}

.user__sidebar-profile-container {
  display: flex;
  gap: 0.4rem;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user__sidebar-profile-container h6 {
  color: #55545f;
}

.user__sidebar-profile-container span {
  color: #55545f;
}

.user__sidebar-img-container {
  /* height: 30%; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.user__input-filter-search-bar {
  padding: 1rem 0;
  width: 30%;
  /* width: auto; */
}
.user__sidebar-img {
  width: 7rem;
  height: 7rem;
  z-index: 1;
  object-fit: cover;
}

.user__sidebar-img-container::before {
  content: ' ';
  height: 6rem;
  width: 9rem;
  position: absolute;
  top: -6%;
  z-index: 0;
  background-color: #44434f;
}

.user__freebies-inner-container {
  display: flex;
  flex-direction: column;
}

.user__freebies-inner-container-inner {
  display: grid;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 60rem;
  max-width: 80rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.user__input-filter-skew-container {
  background-color: #15151c;
  transform: skewX(-25deg);
  display: flex;
  border: 0.2rem solid #282835;
  width: 100%;
}

.user__input-filter-form-control {
  background-color: transparent;
  border: none;
  color: white;
  transform: skewX(25deg);
  padding: 0 1.5rem;
  width: 100%;
}

.user__input-filter-form-control:focus {
  outline: none;
}

.user__filter-search-button {
  background-color: #38383e;
  border: none;
  padding: 0.6rem 1rem;
  color: #ededed;
  cursor: pointer;
  display: flex;
  /* transform: skewX(25deg); */
}

.user__filter-search-button:active {
  transform: scale(1.1);
}

.user__separator {
  height: 1rem;
  background-color: #15151c;
}

.user__tags-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191921;
  padding: 1rem;
  gap: 2rem;
}

.user__feature-tags-container {
  display: flex;
  gap: 6rem;
  width: 100%;
}

.user__feature-tags-item {
  color: #3e3e4f;
  padding: 0.4rem 1rem;
  cursor: pointer;
  transition: color 0.4s ease-in-out, border-bottom 0.4s ease-in-out;
}

.user__feature-tags-item-active {
  color: #d2d7db;
  border-bottom: 0.2rem solid #7c187a;
}

.user__main-profile-container {
  background-color: #15151c;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.user__main {
  display: flex;
  flex-direction: column;
  background-color: #15151c;
}

.user__main-profile-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.user__main-profile-form-group {
  display: flex;
  color: white;
  gap: 4rem;
}

.user__main-profile-label {
  width: 30vw;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #232230;
  transform: skewX(-20deg);
  color: #919198;
}

.user__main-profile-label-inner {
  transform: skewX(20deg);
}

.user__main-profile-form-control-container {
  display: flex;
  flex-direction: column;
  color: aliceblue;
}

.user__main-profile-form-control-container-social {
  display: flex;
  grid-template-columns: repeat(2, 1fr);

  color: aliceblue;
  gap: 3rem;
}

.user__main-profile-form-control-container-social-inner {
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
}

.user__main-profile-skew-container {
  transform: skewX(-20deg);
  border: 0.2rem solid #2b2b38;
  display: flex;
  justify-content: space-between;
}

.user__main-profile-skew-container-social {
  transform: skewX(-20deg);
  border: 0.2rem solid #2b2b38;
  display: flex;
  justify-content: space-between;
}

.user__main-profile-social-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  transform: translateX(20deg);
  padding-left: 0.7rem;
}

.user__main-profile-form-control {
  width: 50vw;
  padding: 1rem 1rem;
  background-color: transparent;
  border: none;
  color: white;
}

.user__main-profile-form-control-social {
  width: 21.5vw;
  padding: 1rem 1rem;
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
}

.user__main-profile-form-control-edit-btn {
  padding: 0.5rem 0.9rem;
  background-color: #38383e;
  margin: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user__main-profile-info {
  color: #ff9700;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
}

.user__main-library-info {
  color: #ff9700;
  font-size: 1rem;
  display: flex;
  /*justify-content: center;*/
  margin-left: 3rem;
  margin-top: 0.3rem;
}

/* Inpage Library */

.user__library {
  background-color: #191921;
  color: white;
  height: 100%;
}

.user__pointy-arrow {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border: 0.3rem solid blue;
  bottom: -30%;
  left: 38%;
  transform: rotate(45deg);
  border-top: 0.2rem solid #2a2a37;
  border-left: 0.2rem solid #2a2a37;
  border-bottom: transparent;
  border-right: transparent;
  background-color: #15151c;
}

.user__main-collection-preview {
  border-top: 0.2rem solid #2a2a37;
  margin: 0 3rem;
  display: flex;
  padding: 3rem;
  gap: 3rem;
  animation: fade-in 0.4s ease-in-out;
}

.user__collection-file-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.1rem;
}

.user__collection-file-container-title {
  display: flex;

  gap: 1rem;
}

.user__collection-preview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user__main-images-main-preview {
  width: 20rem;
  height: 13rem;
  object-fit: cover;
  object-position: center;
}

.user__main-images-preview-container {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
}

.user__main-images-preview {
  width: 4.2rem;
  height: 3rem;
  object-fit: cover;
}

.user__collection-details {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}

.user__collection-details-body {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.user__collection-send-message-btn,
.user__collection-download-all-btn {
  background-color: #af23af;
  transform: skewX(-20deg);
  padding: 0.5rem 0.9rem;
  border: none;
  cursor: pointer;
  outline: 4px solid #bc41bc;
  display: flex;
  color: white;
}

.user__collection-send-message-btn:active,
.user__collection-download-all-btn:active {
  transform: skewX(-20deg) scale(1.1);
}

.user__collection-individual-download {
  color: #15151c;
  transform: skewX(20deg);
  font-size: 1.4rem;
  margin-left: 7rem;
  cursor: pointer;
}
.user__collection-individual-download:active {
  transform: scale(1.1) skewX(20deg);
}

.user__collection-download-all-btn {
  background-color: #ff9700;
  padding: 0.5rem 0.8rem;
  outline: none;
  color: black;
  font-weight: bold;
  justify-content: center;
}
.user__collection-send-message-btn span,
.user__collection-download-all-btn span {
  transform: skewX(20deg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.user__collection-description,
.user__collection-file {
  color: #d3d9dc;
  cursor: pointer;
}

.user__main-img-owner {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
}

.user__span-text {
  color: #606080;
}

.user__collection-file,
.user__collection-description {
  display: flex;
  align-items: center;
}

.user__collection-drop-icon {
  color: #717196;
  font-size: 1.4rem;
  transition: transform 0.4s ease-in-out;
}

.user__collection-main-file {
  padding: 0.4rem;
  display: flex;
  gap: 1.2rem;
  background-color: #232230;
  transform: skewX(-20deg);
}

.user__collection-main-file-inner {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  color: #989da4;
  transform: skewX(20deg);
}
/* User Sales */

.user__sales {
  background-color: #15151c;
  height: 100%;
  color: white;
  display: grid;
  grid-template-columns: 15% 75%;
  width: 100vw;
}

.user__sales-sidebar {
  display: flex;
  flex-direction: column;
}

.user__sales-sidebar-header {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  background-color: #191921;
}
.user__sales-sidebar-header-item {
  padding: 0 1rem;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
}

.user__sales-sidebar-header-item-text {
  animation: 1s fadeIn ease-in-out;
}

.user__sales-sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 3rem;
}

.user__sales-sidebar-body-item {
  background-color: #2b3c2f;
  padding: 0.8rem 1rem;
  width: 100%;
  transform: skewX(-20deg);
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}
.user__sales-sidebar-body-item span {
  transform: skewX(20deg);
  display: flex;
  justify-content: space-around;
  /* gap: 30%; */
  width: 70%;
}

.user__sales-sidebar-bi-selected {
  transform: scaleY(1.1) scalex(1.2) skewX(-20deg);
  background-color: #353449;
}

.user__sales-shi-selected {
  border-bottom: 0.3rem solid #831881;
}

.user__sales-main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color:#15151c;
   /*background-color: red!important;*/ 
}

.user__sales-mc-header {
  display: flex;
  align-items: center;
  background-color: #181821;
  width: 100vw;
  gap: 2rem;
  padding: 2rem 4rem;
}

.user__sales-mc-right {
  color: #e8e8e8;
}

.user__sales-mc-left {
  color: #e8e8e8;
  font-size: 1.3rem;
}

.user__sales-mc-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 1rem;
  background-color: #15151c;
  height: 30vh;
}

.user__sales-mc-item-container {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.user__sales-mc-item-header {
  background-color: #232230;
  color: #9a999f;
  padding: 1rem 4rem;
  padding-right: 6rem;
  position: relative;
  font-weight: 900;
}

.user__sales-mc-item-header::after {
  content: 'ki';
  transform: skewX(-20deg);
  background-color: #15151c;
  color: transparent;
  /* background-color: red; */
  position: absolute;
  right: -3%;
  top: 0;
  width: 3rem;
  height: 100%;
}

.user__sales-mc-item-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user__sales-mc-item-body p {
  font-size: 0.6rem;
  color: #363645;
}

.user__sales-form-group {
  display: flex;
  align-items: center;
  position: relative;
}

.user__sales-mc-dropdown {
  width: 8.5rem;
  height: 2.8rem;
  border: 0.2rem solid #747478;
  outline: 0.2rem solid #2a2a37;
  color: white;
  outline-offset: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transform: skewX(-20deg);
  cursor: pointer;
}

.user__sales-mc-dropdown span {
  transform: skewX(20deg);
}

.user__sales-mc-dropdown-container {
  position: absolute;
  display: flex;
  gap: 1rem;
  background-color: #38383e;
  flex-direction: column;
  top: 110%;

  z-index: 4;
  left: -10%;
  width: 100%;
}
.user__sales-mc-dropdown-container span:hover {
  background-color: #2a2a37;
  cursor: pointer;
}

.user__sales-mc-dropdown-container span {
  padding: 2rem;
}

.user__sales-mc-dropdown-icon {
  font-size: 2rem;
  left: -14%;
  position: relative;
  cursor: pointer;
  /* position: absolute;
    right: 0; */
}

.user__library-edit-folder-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #353449;
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  top: 2.8rem;
  z-index: 99;
}

.user__library-edit-folder-dropdown-item {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.user__library-edit-folder-dropdown-item:hover {
  cursor: pointer;
  display: flex;
}

.user__sales-skew-container {
  transform: skewX(-20deg);
  display: flex;
  border: 0.2rem solid #2a2a37;
}

.user__sales-mc-input {
  transform: skewX(20deg);
  background-color: transparent;
  padding: 1rem 4rem;
  color: white;
  border: none;
  width: 35rem;
}

.user__sales-mc-input:focus {
  outline: none;
}

.user__sales-mc-input-edit-skew-container {
  transform: skewX(-20deg);
  background-color: #38383e;
  padding: 0.5rem 1.1rem;
  /* width: 20rem; */
  /* height: 2rem;  */
  display: flex;
  left: -10.5%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.user__sales-mc-input-edit {
  font-size: 1.5rem;

  cursor: pointer;

  color: #ffffff;
}

.user__sales-mc-input-edit-skew-container:active {
  transform: skewX(-20deg) scale(1.1);
}

.user__sales-mc-item-container.button {
  display: flex;
  padding-left: 46%;
}

.user__library-inner {
  display: flex;
  gap: 1rem;
}

.user__sales-mc-item-btn {
  padding: 1rem 4rem;
  background-color: #ff9700;
  border: none;
  outline: #ffc46e 0.2rem solid;
  outline-offset: -6px;
  color: #fff3e2;
  font-weight: bold;
  transform: skewX(-20deg);
  display: flex;
  cursor: pointer;
}

.user__sales-mc-item-btn:active {
  transform: scale(1.1) skewX(-20deg);
}

/* .user__sales-mc-item-btn:focus{
    transform: scale(1.1);
} */
.user__sales-mc-item-btn span {
  transform: skewX(20deg);
}

.user__sales-inner {
  display: flex;
  gap: 1rem;
}

.user__library-sidebar {
  display: flex;
  flex-direction: column;
  height: 38vw;
  width: 100%;
  flex: 0.9;
  background-color: #232230;
  position: relative;
}

.user__sales-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 0.9;
  background-color: #232230;
}

.user__library-sidebar-header {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  background-color: #191921;
}

.user__library-sidebar-header-item {
  padding: 0.3rem 2rem;
  position: relative;
  text-transform: uppercase;
}

.user__library-sidebar-header-item-text {
  animation: 1s fadeIn ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.user__library-sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 3rem;
}

.trash-button-section {
  position: absolute;
  bottom: 5rem;
  width: 100%;
}

.settings-trash-button-section {
  margin-top: 6rem;
  width: 100%;
}

.user__library-trash-btn {
  cursor: pointer;
}

.user__library-item {
  transform: skewX(-20deg);
  border: 0.1rem solid transparent;
  background-color: #353449;
  color: #6d6d91;
  padding: 0.8rem 1.8rem;
  width: 80%;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out,
    color 0.4s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
}

/* .user__library-item:hover {
  border: 0.1rem solid #7e7d86;
} */

.user__library-item-active {
  border: 0.1rem solid #7e7d86;
  transform: scaleX(1.1) skewX(-20deg);
  color: white;
}

.user__library-item-inner {
  display: flex;
  transform: skewX(20deg);
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.user__library-item-info-count {
  font-size: 0.6rem;
  color: #6d6d91;
  position: absolute;
  bottom: 8%;
  right: 8%;
  transform: skewX(20deg);
}

.user__lib-folder-large {
  font-size: 1rem;
  transition: font-size 0.3s ease-in-out;
  animation: smoothSwitch 0.4s ease-in-out;
  cursor: pointer;
}

.not-active:hover {
  font-size: 0.9rem;
  color: #af23af;
}

@keyframes smoothSwitch {
  0% {
    font-size: 1rem;
  }

  100% {
    font-size: 1.4rem;
  }
}

.user__library-item-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.user__library-item-name {
  font-size: 0.8rem;
  display: flex;
  gap: 0.8rem;
  margin-right: 1rem;
  cursor: pointer;
}

.user__library-item-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}

.user__library-item-icon:hover {
  color: #af23af;
}

.user__library-sidebar-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user__library-trash-btn {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #232230;
  color: #7b7a83;
  padding: 0.5rem 0.5rem;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.mobile-user__library-trash-btn {
  width: 98%;
  margin: 2rem auto;
  padding: 1rem 0.5rem;
  display: flex;
}

.mobile-library-nav-btn {
  width: 40%;
  margin: 1rem auto;
}

.user__edit-folder-input-box {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #15151c;
  color: #7b7a83;
  padding: 0.5rem 0.5rem;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.user__library-trash-btn input {
  background-color: transparent;
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  color: #ffffff;
}

.user__edit-folder-input-box input {
  background-color: transparent;
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  color: #ffffff;
}

.user__sales-trash-btn {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #232230;
  color: #7b7a83;
  padding: 0.5rem 0.5rem;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.user__library-trash-btn-logo {
  transform: skewX(20deg);
  font-size: 1.2rem;
  color: #af23af;
  cursor: pointer;
}

.user__library-trash-btn-text {
  transform: skewX(20deg);
}

.user__main-lib-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 3;
  background-color: #15151c;
  min-height: 30vh;
}

.user__main-lib-header {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  align-items: center;
  /* padding: 1.4rem 2rem; */
  background-color: #191921;
  height: 3.8rem;
}

.user__main-lib-header-text {
  font-size: 0.8rem;
  color: #3e3e4f;
}

.user__main-lib-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 3rem 3rem;
  gap: 2rem;
  justify-content: space-around;
}

.user__main-lib-body-collections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 3rem 3rem;
  gap: 2rem;
  justify-content: space-around;
  align-items: center;
}

.user__main-lib-card {
  display: flex;
  flex-direction: column;
  background-color: #232230;
  position: relative;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.user__main-lib-card:hover {
  transform: scale(1.05);
}

.user__main-lib-card-img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  object-position: 50% 50%;
}

.user__main-lib-card-body {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.7rem 0.4rem;
  justify-content: center;
  align-items: center;
}

.user__card-collection-title {
  font-size: 0.9rem;
  color: #d2d7db;
}

.user__card-collection-description {
  font-size: 0.7rem;
  color: #6d6d91;
}

.user__main-lib-card-footer {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #d2d7db;
  padding: 0.4rem 0.4rem;
  cursor: pointer;
  transition: color 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.user__main-lib-card-footer:hover {
  color: white;
}

.user__main-lib-card-footer:active {
  transform: scale(1.05);
}

.user__main-lib-footer {
  background-color: #15151c;
}

.user__main-lib-body-left {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.user__main-lib-item {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.user__main-lib-checkbox {
  width: 1.4rem;
  height: 1.2rem;
  border: 0.2rem solid #38383e;
  transform: skewX(-20deg);
  padding: 0.1rem;
  transform: 0.3s transform ease-in-out;
  cursor: pointer;
}

.user__main-lib-checkbox:active {
  transform: scale(1.1) skewX(-20deg);
}

.user__main-lib-checkbox-active {
  width: 0.8rem;
  height: 0.6rem;
  background-color: #ff9700;
  transition: 0.3s background-color ease-in-out;
}

.user__main-lib-item-body {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.user__main-lib-item-header {
  color: #5c5c7d;
}

.user__main-lib-item-main {
  font-size: 0.8rem;
  color: #393948;
}

/* .user__library-card {
    transform: skewX(-20deg);
    color: white;
    display: flex;
}

.user__library-card-img {
    width: 13rem;
    height: 7rem;
    object-fit: cover;
}

.user__library-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user__library-card-name {
    background-color: #353449;
    color: #515170;
    font-weight: bold;
    width: 20rem;
    padding: 1.5rem 1.4rem;
    position: relative;
    display: flex;
}

.user__upload-pg-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 6%;
    top: 12%;
    transform: skewX(20deg);
}

.user__library-card-pg-container {
    background-color: transparent;
    height: .4rem;
    width: 100%;
}

.user__upload-pg-stats {
    font-size: .7rem;
    color: #232230;
}

.user__library-pg-bar {
    background-color: #FF9700;
    width: 50%;
    height: 100%;
} */

/* Messages In page */

.user__messages {
  background-color: #191921;
  color: white;
  height: 100%;
  position: 'relative';
}

.user__messages-inner {
  /* grid-template-columns: 25% 75%; */
  display: flex;
  gap: 1rem;
  height: 100%;
}

.user__message-sidebar {
  display: flex;
  flex-direction: column;
  /* height: 100%!important; */
  width: 100%;
  flex: 0.9;
  background-color: #232230;
}

.user__message-sidebar-header {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  background-color: #191921;
}

.user__freebies-sidebar-header {
  display: flex;
  justify-content: space-around;
  overflow-x: scroll;
  padding: 1rem 0.5rem;
  background-color: #191921;
  width: 100%;
  height: 5rem;
}

.user__message-sidebar-header-item {
  padding: 0.3rem 2rem;
  position: relative;
  width: 4rem;
  cursor: pointer;
  transition: border-bottom 0.4s ease-in-out;
  border-bottom: 0.2rem solid transparent;
}

.user__freebies-sidebar-header-item {
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: border-bottom 0.4s ease-in-out;
  border-bottom: 0.2rem solid transparent;
}

.user__message-sidebar-header-item-active {
  border-bottom: 0.2rem solid #7c187a;
}

.user__freebies-sidebar-header-item-active {
  border-bottom: 0.2rem solid #7c187a;
}

.users__suscribers-container {
  display: flex;

  height: 60vh;
  align-items: center;
  justify-content: center;
  background-color: #15151c;
}

.users__suscribers-container-inner {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 1rem;
  max-width: 90rem;
  gap: 2rem;
  width: 90%;
  margin: 0 auto;
}
.users__suscribers-card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 25rem;
  background-color: #232230;
}

.users__suscribers-count-title {
  color: white;
  font-weight: bold;
}

.users__suscribers-count-info {
  color: #7b7a83;
  text-align: center;
}

.users__suscribers-count-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.users__bottom-bg-image-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 3;
  padding: 0.5rem;
  padding-top: 4rem;
  gap: 1rem;
}

.users__top-bg-image-profile {
  position: absolute;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  top: 25%;
}

.users__suscribers-location {
  color: white;
}

.users__suscribers-btn {
  padding: 0.6rem 1.2rem;
  color: white;
  display: flex;
  transform: skewX(-20deg);
  border: none;
  background-color: #15151c;
}

.users__suscribers-btn span {
  transform: skewX(20deg);
}
.users__suscribers-count {
  color: #94949b;
}
.users__top-bg-image {
  background-color: blue;
  flex: 1;
  height: 35%;
}
.users__top-bg-image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: blue;
}
.user__message-notify {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #ff9700;
  border-radius: 100%;
  position: absolute;
  right: 10%;
  top: 10%;
}

.user__message-sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 3rem;
}

.user__message-friend {
  transform: skewX(-20deg);
  border: 0.1rem solid transparent;
  background-color: #353449;
  color: white;
  padding: 0.4rem 0.8rem;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out;
  cursor: pointer;
}

.user__message-friend:hover {
  border: 0.1rem solid #7e7d86;
}

.user__message-friend-active {
  border: 0.1rem solid #7e7d86;
  transform: scaleX(1.1) skewX(-20deg);
}

.user__message-friend-inner {
  display: flex;
  transform: skewX(20deg);
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.user__message-friend-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}

.user__message-friend-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user__msg-friend-name {
  font-size: 1rem;
  display: flex;
  gap: 0.8rem;
  color: #ffff;
}

.user__msg-time {
  color: #6d6d91;
  font-size: 0.8rem;
}

.user__msg-preview {
  color: #6d6d91;
  font-size: 0.8rem;
}

.user__message-sidebar-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user__message-trash-btn {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #232230;
  color: #7b7a83;
  padding: 0.7rem 0.7rem;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.user__message-trash-btn-logo {
  transform: skewX(20deg);
  color: #ff0000;
}

.user__message-trash-btn-text {
  transform: skewX(20deg);
}

.user__main-msg-container {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  width: 100%;
  flex: 3;
  background-color: #15151c;
}

.user__main-msg-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 1.4rem 2rem; */
  background-color: #191921;
  height: 3.8rem;
}

.user__main-msg-header-text {
  border-left: 0.2rem solid #2b2b38;
  padding: 0.5rem 4rem;
  position: relative;
  left: -1.5%;
}

.user__main-msg-body {
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
  gap: 3rem;
  overflow-y: scroll;
  justify-self: flex-end;
  flex: 3;
  height: 40vh;
  justify-content: flex-end;
}

.user__main-sender-msg-cont {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.user__main-sender-msg-img {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
}

.user__main-sender-msg-inner {
  display: flex;
  flex-direction: column;
  background-color: #232230;
  padding: 2rem 3rem;
  width: 70%;
}

.user__main-sender-talk {
  flex: 3;
  color: 'white';
}

.user__main-sender-time {
  flex: 1;
  color: 'white';
}

/* receiver */

.user__main-receiver-msg-cont {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 2rem;
  width: 70%;
}

.user__main-receiver-msg-img {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
}

.user__main-receiver-msg-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #232230;
  padding: 2rem 3rem;
  width: 100%;
}

.user__main-receiver-talk {
  flex: 3;
  color: 'white';
}

.user__main-receiver-time {
  flex: 1;
  color: 'white';
}

/* Send parameters */

.user__main-msg-footer {
  background-color: #15151c;
  /* height: 40%; */
  /* flex: 1; */
}

.user__main-msg-send-container {
  display: flex;
  justify-content: center;
}

.user__msg-form-group {
}

.user__msg-form-skew-container {
  transform: skewX(-20deg);
  border: 0.2rem solid #2b2b38;
  /* padding: .05rem; */
  display: flex;
  align-items: center;
  width: 40vw;
  gap: 0.3rem;
}

.user__msg-form-doc-btn {
  background-color: #38383e;
  padding: 0.8rem 2rem;
  color: #5d5d62;
  font-size: 1rem;
  border: none;
}

.user__msg-form-control {
  width: 100%;
  border: none;
  transform: skewX(20deg);
  background-color: transparent;
  color: white;
}

.user__msg-form-control:focus {
  outline: none;
}

.user__msg-form-send-btn {
  background-color: #38383e;
  padding: 0.8rem 2rem;
  color: #5d5d62;
  border: none;
  color: white;
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  height: 100%;
}

/* User Settings section */

.user__settings {
  background-color: #191921;
  color: white;
  min-height: 60vh;
}

.user__settings-inner {
  display: flex;
  gap: 1rem;
  min-height: 60vh;
}

.user__settings-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0.9;
  flex-grow: 1;
  background-color: #232230;
  position: relative;
}

.user__settings-sidebar-header {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  background-color: #191921;
}

.user__settings-sidebar-header-item {
  padding: 0.3rem 2rem;
  position: relative;
  text-transform: uppercase;
}

.user__settings-sidebar-header-item-text {
  animation: 1s fadeIn ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.user__settings-sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 3rem;
}

.user__settings-item {
  transform: skewX(-20deg);
  border: 0.1rem solid transparent;
  background-color: #353449;
  color: #6d6d91;
  padding: 0.8rem 1.8rem;
  width: 80%;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out,
    color 0.4s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.user__settings-item:hover {
  border: 0.1rem solid #7e7d86;
}

.user__settings-item-active {
  border: 0.1rem solid #7e7d86;
  transform: scaleX(1.1) skewX(-20deg);
  color: white;
}

.user__settings-item-inner {
  display: flex;
  transform: skewX(20deg);
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.user__settings-item-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user__settings-item-name {
  font-size: 0.8rem;
  display: flex;
  gap: 0.8rem;
}

.user__settings-sidebar-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user__settings-trash-btn {
  transform: skewX(-20deg);
  border: 0.2rem solid #353449;
  background-color: #232230;
  color: #7b7a83;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
  cursor: pointer;
}

.user__settings-trash-btn-logo {
  transform: skewX(20deg);
  font-size: 1.2rem;
  color: #af23af;
  cursor: pointer;
}

.user__settings-trash-btn-text {
  transform: skewX(20deg);
}

.user__main-sett-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 3;
  background-color: #15151c;
}

.user__main-sett-header {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  align-items: center;
  /* padding: 1.4rem 2rem; */
  background-color: #191921;
  height: 3.8rem;
}

.user__main-sett-header-text {
  font-size: 0.8rem;
  color: #3e3e4f;
}

.user__main-sett-body {
  display: flex;
  padding: 3rem 3rem 3rem 6rem;
  gap: 2rem;
  /* justify-content: space-around; */
}

.user__main-sett-footer {
  background-color: #15151c;
  display: flex;
  padding: 3rem 3rem 3rem 6rem;
  gap: 2rem;
  flex-direction: column;
}

.user__settings-footer-divider {
  height: 1px;
  border: 1px solid #38383e;
  width: 45%;
  margin-bottom: 2rem;
}

.user__main-sett-body-left {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.user__main-sett-item {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  color: #fff;
}

.user__main-sett-checkbox {
  width: 1.4rem;
  height: 1.2rem;
  border: 0.2rem solid #38383e;
  transform: skewX(-20deg);
  padding: 0.1rem;
  transform: 0.3s transform ease-in-out;
  cursor: pointer;
}

.user__main-sett-checkbox:active {
  transform: scale(1.1) skewX(-20deg);
}

.user__main-sett-checkbox-active {
  width: 0.8rem;
  height: 0.6rem;
  background-color: #ff9700;
  transition: 0.3s background-color ease-in-out;
}

.user__main-sett-item-body {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.user__main-sett-item-header {
  color: #fafafa;
}

.user__main-sett-item-main {
  font-size: 0.8rem;
  color: #cac9c9;
}

/* Upload Section */

.upload_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.upload__mock-up {
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 6rem;

  background-color: #15151c;
  padding: 3rem 0;
}

.upload__title-section-user {
  display: flex;
  flex: 2;
  gap: 3rem;
  width: 55%;
}
@media only screen and (max-width: 768px) {
  .upload__title-section-user {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
}
.upload__title-container {
  display: flex;
  color: white;
  justify-content: flex-start;
  align-items: flex-start;
}

.upload__title {
  padding: 1rem 4rem;
  background-color: #232230;
}

.upload__main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
}

.upload__form-group {
  display: flex;
  border: 0.3rem solid #2b2b39;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
  position: relative;
  padding-right: 0.2rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.upload__form-group-extra {
  display: flex;
  border: 0.3rem solid #2b2b39;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
  position: relative;
  padding: 0.5rem;
}

.upload__form-control {
  background-color: transparent;
  padding: 1rem 1rem;
  border: none;
  color: white;
  width: 100%;
}

.upload__form-control:focus {
  outline: none;
}

.upload__form-edit-btn {
  padding: 0.45rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  /* position: absolute;
    right: 1%;
    top: 30%; */
  background-color: #2b2b38;
  color: white;
  border: none;
  font-size: 1.5rem;
}

.upload__form-edit-btn:active {
  transform: scale(1.1);
}

.upload__image-dropbox-container-user {
  height: 450px;
  width: 80%;
  border: 0.2rem solid #2b2b39;
  padding: 0.8rem;
  position: relative;
}

.upload__image-dropbox {
  border: 0.2rem dashed red;
  opacity: 0;
  height: 70%;
  position: absolute;
  width: 70%;
  top: 15%;
  left: 15%;
  cursor: pointer;
}
.upload__image-dropbox::-webkit-file-upload-button {
  visibility: hidden;
}

.upload__image-dropbox-twin {
  border: 0.2rem dashed #2b2b38;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  transition: 0.5s border ease-in-out;
}

.upload__image-dropbox-twin:hover {
  border: 0.2rem dashed #585762;
  cursor: pointer;
}

.upload__image-dropbox-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  height: 100%;
  width: 100%;
}
.upload__image-dropbox-hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.upload__image-dropbox-hover:hover {
  opacity: 1;
}

.upload__image-head {
  color: #f6f6f7;
  font-size: 1.3rem;
}

.upload__image-desc {
  font-size: 0.9rem;
  color: #5d5d7b;
}

.upload__image-preview-user {
  display: flex;
  gap: 2rem;
  padding: 2rem 0rem;
  padding-top: 0rem;
  width: 80%;
  overflow-x: scroll;
}

.upload__image-preview-user::-webkit-scrollbar {
  width: 3px;
  height: 9px;
}

.upload__image-preview-user::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
  background-color: #232230;
}

.upload__image-preview-user::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #3e3e4f;
}

.upload__image-preview-inner-user {
  display: inline-flex;
  gap: 2rem;
  transition: transform 0.6s ease-in-out;
}

.upload__image-item {
  height: 8rem;
  width: 14rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border: 0.3rem solid #2c2c3a;
  color: #737377;
  font-size: 1.1rem;
}

.upload__image-form-control {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload__image-form-control-image {
  width: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0.2;
  object-fit: cover;
  height: 100%;
}

.upload__image-form-control:active {
  opacity: 0.1;
  background-color: #44434f;
}
.upload__image-iconister {
  font-size: 1.6rem;
}

.upload__description-section-user {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
  width: 40%;
  padding-right: 3rem;
}
.upload__text-area-container {
  position: relative;
}

.upload__text-area {
  background-color: transparent;
  color: white;
  padding: 1rem;

  border: 0.2rem solid #2c2c3a;
  width: 100%;
}

.upload__text-area-btn {
  background-color: #2b2b38;
  color: white;
  padding: 0.5rem;
  border: none;
  position: absolute;
  bottom: 7%;
  right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.upload__tags-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upload__tags-inner-container-user {
  display: grid;
  gap: 0.2rem;
  grid-template-columns: repeat(4, 1fr);
  width: 80%;
}

.upload__tag-btn {
  padding: 0.5rem 1rem;
  background-color: #232230;
  color: #69698b;
  border: none;
  cursor: pointer;
  position: relative;
}

.upload__tag-selected-user {
  position: absolute;
  top: 30%;
  left: 9%;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: transparent;
}

.upload__tag-selected-transparent-user {
  background-color: #aa24ac;
  transition: background-color 0.3s ease-in-out;
}

.upload__tags-title-container {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.upload__tags-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #d5dade;
}

.upload__tag-select {
  color: #5e5e7c;
  font-size: 0.9rem;
}

.upload__main-file-upload-container {
  width: 100%;
  height: 6rem;
  cursor: pointer;
  padding: 1.5rem auto;
}

.upload__main-files-upload-twin {
  border: 0.2rem solid #2b2b38;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.upload__fileContainer {
  width: 100%;
}
.upload__fileList {
  display: flex;
  border: 0.2rem solid #2b2b38;
  justify-content: stretch;
  padding: 4px;
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
}
.upload__fileInput {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.upload__fileList li,
.product_file {
  background-color: #232230;
  padding: 4px 0px 4px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.upload__fileRemove {
  color: #aa24ac;
  padding: 0 4px;
  font-size: 1.5rem;
  height: 100%;
}
.upload__main-files-upload-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #efefef;
  cursor: pointer;
}

.upload__main-files-upload {
  opacity: 0;
  cursor: pointer;
  border: 0.2rem dotted blue;
  position: absolute;
  top: 7%;
  left: 10%;
  width: 80%;
  height: 80%;
}

.upload__label {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  color: #d5dade;
  cursor: pointer;
}
.upload__fileUploadInput {
  display: none;
}
.upload__main-files-upload:active {
  opacity: 0.1;
}

.upload__price-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #232230;
  padding: 1rem 2rem;
  gap: 1rem;
}

.upload__price-inner-section {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.3rem;
}

.upload__form-group-commercial {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.upload__form-group-commercial-inactive {
  opacity: 0.4;
}

.upload__skew-container {
  transform: skewX(-15deg);
  display: flex;
  gap: 0.4rem;
}

.upload__main-control-container {
  background-color: #121218;
  border: 0.3rem solid #2b2b38;
  padding: 0.1rem;
  display: flex;
  gap: 0.3rem;
  height: 100%;
}

.upload__control-switch-container {
  border: 0.2rem solid #7c7c7f;
  display: flex;
}

.upload__license-text {
  color: #69698b;
  font-size: 0.6rem;
}

.upload__control-usd-btn {
  padding: 0.5rem;
  display: flex;
  background-color: #737377;
  border: 0.2rem solid #ababad;
  border-right: none;
  color: #f8f8f8;
  font-weight: bold;
}

.upload__control-usd-btn span {
  transform: skewX(15deg);
}
.upload__price-input-user {
  background-color: transparent;
  border: none;
  color: white;
  transform: skewX(15deg);
  padding: 0.4rem;
  width: 30px;
}

.upload__price-input-user:focus {
  outline: none;
}

.upload__container-controller {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
}

.upload__controller-btn {
  padding: 0.1rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7b7a83;
  color: #303037;
  cursor: pointer;
}

.upload__controller-btn:active {
  transform: scale(1.03);
}

.upload__controller-btn span {
  transform: skewX(15deg);
  font-size: 1rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
}

.upload__more-text {
  color: #f29003;
  cursor: pointer;
}

.upload__publish-section-user {
  display: flex;
  justify-content: space-between;
  transform: skewX(-25deg);
  padding: 1rem;
}
.upload__publish-section-user:disabled {
  cursor: not-allowed;
}
.upload__draft-btn-user,
.upload__publish-btn-user {
  padding: 1.2rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline-offset: -5px;
  color: white;
  border: none;
  width: 100%;
}
.upload__publish-btn-user:disabled {
  cursor: not-allowed;
}
.upload__draft-btn-user:disabled {
  cursor: not-allowed;
}
.upload__fileUploadBtn {
  padding: 1rem 7rem;
  outline: 0.2rem solid #ffc268;
  outline-offset: -5px;
  color: white;
  border: none;
  background-color: #ff9700;
  transform: skewX(-25deg);
  margin-top: 1rem;
  align-self: center;
}
.upload__fileUploadBtn:disabled {
  cursor: not-allowed;
}
.upload__draft-btn-user span,
.upload__publish-btn-user span {
  transform: skewX(25deg);
}

.upload__draft-btn-user {
  outline: 0.2rem solid #4e4d58;
  outline-offset: -5px;
  color: white;
  border: none;
  background-color: #232230;
}

.upload__publish-btn-user {
  outline: 0.2rem solid #ffc268;
  outline-offset: -5px;
  color: white;
  border: none;
  background-color: #ff9700;
}

/* user sales balance */

.user__sales-balance-body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.user__sales-balance-first-row {
  display: flex;
  gap: 1rem;
  height: 40vh;
  /*background-color: red;*/
}

.user__sales-balance-announcer-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.user__sales-balance-ann-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  padding: 1rem;
  gap: 8px;
  background-color: #232230;
}

.user__sales-balance-ann-card.selected-yellow {
  border-bottom: 0.4rem solid #ff9700;
}

.user__sales-balance-ann-card.selected-purple {
  border-bottom: 0.4rem solid #7c187a;
}

.user__sales-balance-ann-card-title {
  color: #50505b;
}

.user__sales-balance-ann-card-value {
  color: #909096;
  font-size: 1.4rem;
  font-weight: bolder;
}

.user__sales-statistics-box {
  display: flex;
  flex-direction: column;
  flex: 6;
  /* background-color: red; */
  width: 100%;
}

.user__sales-statistics-box-header {
  display: flex;
  gap: 0.5rem;

  font-size: 1.2rem;
}

.user__sales-header-text {
  color: #53535d;
  background-color: #232230;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 9;
}

.user__sales-statistics-time-dropdown {
  display: flex;
  background-color: #232230;
  padding: 0.5rem 2rem;
  border: 0.2rem solid #2b2b38;
  position: relative;
  flex: 1;
  color: #ffffff;
  cursor: pointer;
}

.user__sales-statistics-time-dropdown-icon {
  position: absolute;
  right: 1%;
  top: 30%;
}

.user__sales-statistics-time-dropdown-modal {
  position: absolute;
  background-color: #232230;
  bottom: -414%;
  left: 0%;

  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.user__sales-statistics-time-dropdown:hover
  ~ .user__sales-statistics-time-dropdown-modal {
  display: flex;
}

.user__sales-statistics-drop-item {
  cursor: pointer;
  border-bottom: 0.2rem solid #2b2b39;
  padding: 1.5rem 2rem;
  color: #ffffff;
}

.user__sales-statistics-drop-item:active {
  background-color: #353449;
}
.user__sales-statistics-drop-item:hover {
  cursor: pointer;
}

.user__sales-statistics-box-body {
  display: flex;
  height: 100%;
}

.user__sales-stats-side {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem;
}

.user__sales-stats-side-count {
  color: #6d6d72;
}

.user__sales-statistics-box-body-inner {
  display: flex;
  width: 100%;
}

.user__sales-statistics-box-body-inner:last-child {
  border-right: 0.2rem solid #1b1b25;
  margin-top: 12rem;
}
.user__sales-statistics-bar {
  padding: 0.3rem 0.2rem;
  width: 12%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-left: 0.2rem solid #1b1b25;
}

.user__sales-statistics-bar-percentage {
  background-color: #7c187a;
  width: 100%;
  text-align: center;
}

.user__sales-statistics-bar-dater {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.user__sales-balance-second-row {
  display: flex;
  gap: 1rem;
}

.user__sales-next-payout {
  flex: 2;
  /*margin-top: 10rem;*/
}

.user__sales-next-payout-text {
  background-color: #232230;
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
}

.admin__home-ra-inner {
  flex: 6;
}

.user__sales-next-payouts {
  flex: 2;
  position: relative;
}

.user__sales-payout-withdraw-btn {
  display: flex;
  gap: 1rem;
  position: relative;
}

.user__sales-irregular-btn {
  background-color: #353449;
  border: 0.2rem solid #4e4e5e;
  padding: 1rem 1rem;
  transform: skewX(-25deg);
  cursor: pointer;
  /* clip-path: polygon(0% 0%, 0% 100%, 100% 100%); */
}

.user__sales-irregular-btn:active {
  transform: scale(1.05) skewX(-25deg);

  /* clip-path: polygon(0% 0%, 0% 100%, 100% 100%); */
}

.user__sales-irregular-spacer {
  content: ' ';
  width: 100%;
  height: 116%;
  border: 0.2rem solid #4f4f5a;
  border-left: 0.1rem solid transparent;
  background-color: #353449;
  transform: skewX(25deg);
  padding: 0 0.5rem;
  top: -7%;
  right: -70%;
  /* display: block; */
  width: 2rem;
  position: absolute;
}

.user__sales-next-payouts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user__sales-irregular-btn-icon {
  transform: skewX(25deg);
  position: absolute;
  z-index: 2;
}

.account-sett-body,
.user__store-sett-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8rem;
  padding-top: 5rem;
  gap: 2rem;
  padding-bottom: 8rem;
}

.account-sett-input-label {
  font-weight: 600;
  color: #ffffff;
  padding-left: 1rem;
}

.user__account-sett-input-info {
  font-size: 16px !important;
  margin-top: 0.5rem;
}

.user__account-sett-sensitive-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.user__store-sett-url-input-prefix {
  color: #757575;
  text-align: center;
  font-size: small;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 2rem;
}

.user__store-products-header {
  width: fit-content;
  margin: 0 auto;
}

.user__store-products-header-nav {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  margin: 0 1rem;
}

.user__store-share-store-link-box {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: skewX(-25deg);
  border: 0.4rem solid #2b2b38;
  background-color: #15151c;
  margin: 0 1rem;
  cursor: pointer;
}
.makeStyles-iconContainer-3 {
  display: flex;
  justify-content: space-between !important;
}

.user__store-share-link-btn {
  background-color: #38383e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  position: relative;
  cursor: pointer;
}

.user__store-share-link-text {
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  color: #3e3e4f;
}

.withdrawal-modal {
  background-color: #232230 !important;
  overflow-y: auto;
}

.user__withdrawal-modal-heading {
  text-align: center;
  font-size: 26px;
  margin-bottom: 2rem;
}

.user__withdrawal-modal-balance-amount-box {
  margin: 2rem auto;
  text-align: center;
}

.user__withdrawal-modal-balance-amount {
  padding: 0.3rem 0.5rem;
  background-color: #38383e;
  margin-left: 1rem;
}

.user__withdrawal-modal-payment-header {
  justify-content: center;
  padding: 1rem 0.5rem;
  font-weight: 700;
  width: 80%;
  margin: 2rem auto;
  text-align: center;
  background-color: #38383e;
  font-size: 18px;
}

.user__withdrawal-modal-input-container {
  width: 80%;
  margin: 0.5rem auto;
  position: relative;
}

.user__withdrawal-modal-input-box {
  background-color: #15151c;
  padding: 1rem 0.5rem;
  margin-bottom: 0.5rem;
}

.user__withdrawal-input,
.user__withdrawal-input-div {
  background-color: transparent;
  height: 100%;
  width: 85%;
  outline: none;
  border: none;
  color: #38383e;
  position: relative;
}

.user__withdrawal-input-div {
  width: 100%;
}

.user__withdrawal-input span {
  cursor: pointer;
}

.user__withdrawal-modal-currency-indicator {
  color: #38383e;
  margin-right: 1rem;
}

.user__withdrawal-modal-all-balance-indicator {
  color: #ff9700;
}

.user__withdrawal-input-divider {
  height: 1px;
  border: 1px solid #38383e;
  width: 100%;
  margin-bottom: 2rem;
}

.user__withdrawal-modal-pay-me-container {
  align-items: center;
}

.user__withdrawal-modal-pay-me-amount-indicator-box {
  font-size: 28px;
  color: #ff9700;
  margin: 1rem auto 2rem auto;
  padding: 0.5rem 1rem;
  background-color: #38383e;
}

.user__withdrawal-modal-container {
  width: fit-content;
  margin: 1.5rem auto;
}

.user__withdrawal-payment-dropdown {
  background-color: #15151c;
  width: 100%;
  outline: none;
  border: none;
  color: #38383e;
}

.user__withdrawal-payment-dropdown option {
  padding: 1rem 0.5rem;
  color: #ffffff;
}

.user__withdrawal-arrow-icon {
  position: absolute;
  right: 0;
}

.user__edit-folder-modal-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user__edit-folder-span {
  text-align: center;
  color: #38383e;
  margin-bottom: 1rem;
}

.user__edit-folder-skew-header-container {
  display: flex;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  background-color: #15151c;
  justify-content: center;
  align-items: center;
  transform: skewX(-25deg);
  color: #38383e;
  border: none;
  border-left: 0.5rem solid #38383e;
  border-right: 0.5rem solid#38383e;
  width: 90%;
  margin: 2rem auto;
}

.user__edit-folder-add-products-btn {
  border: 1px solid #38383e;
  padding: 0.9rem 1rem;
  width: 50%;
  margin: auto;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.user__edit-folder-add-product-icon {
  font-size: 1.2rem;
  color: #af23af;
  margin-right: 0.9rem;
}

.user__edit-folder-product-card {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0.6rem auto;
}

.user__edit-folder-product-card-img {
  width: 10%;
  object-fit: contain;
}

.user__edit-folder-product-title-box {
  padding: 0.7rem 2rem;
  text-align: center;
  width: 85%;
  height: 98%;
  margin: auto 1%;
  background-color: #414053;
}

.user__edit-folder-product-card-title {
  align-self: center;
}

.user__edit-folder-product-card-close-btn {
  align-self: center;
  cursor: pointer;
}

.user__edit-folder-modal-info-header {
  width: 95%;
  margin: 2rem auto;
  background-color: #414053;
  text-align: center;
  color: #74747d;
  padding: 1.4rem 2rem;
}

.user__delete-folder-modal-text {
  text-align: center;
  font-size: 1.1rem;
}

.user__delete-folder-btn-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 2rem auto;
  justify-content: space-between;
}
.upload_main-content-category-container-user {
  display: flex;
  overflow-x: scroll;
  gap: 1rem;
  width: 80%;
  padding: 1rem 0;
}

@media screen and (max-width: 900px) {
  .upload__image-dropbox-container-user {
    height: 40vh;
    width: 100%;
  }

  .upload_main-content-category-container-user {
    max-width: 100%;
    width: 100%;
  }

  .upload__image-preview-user {
    width: 100%;
    max-width: 100%;
  }
  .upload__description-section-user {
    width: 100%;
    padding-right: 0;
    padding: 1rem;
  }
}
