/* 
  Extra large screens, TV
*/

@media screen and (min-width: 1201px) {
  /* PARTNERTS */
 .coming__soon-new-supporters-box {
   width: 65%;
   gap: 1rem;
   overflow-x: auto;
 } 
 .coming__soon-request-access-modal{
   width: 80%;
 }

 .coming__soon-new-request-access-modal{
   width: 40%;
   margin-left: 59%;
 }
}


/* 
    Desktops, large screens
*/
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  /* PARTNERTS */
  .coming__soon-new-supporters-box {
    grid-template-columns: repeat(3, 1fr);
    width: 60%;
    gap: 2rem;
  } 

  .coming__soon-request-access-modal{
    width: 88%;
  }
  .coming__soon-new-request-access-modal{
    width: 45%;
    margin-left: 54%;
  }
}

/* 
    Small screens, laptops
*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
  /* PARTNERTS */
  .coming__soon-new-supporters-box {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .coming__soon-assets-container {padding-bottom: 3rem;}

  /* NAVBAR */
  .landing__navbar{justify-content: unset;}
  .desktop-coming__soon-navbar-request-access{
    width: 30%;  
  }
  /* .landing__navbar-brand{margin-left: 40%;} */

  .coming__soon-request-access-modal{
    width: 88%;
  }

  .coming__soon-new-request-access-modal{
    width: 49%;
    margin-left: 50%;
  }

  .comming__soon-hero-text-box{
    width: 50%;
    left: 22%;
  }
}

/* 
    iPads, Tablets
*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .coming__soon-battlecry-container{
    background-image: url(/public/assets/image/comingsoon/coming-soon-hero-mobile.png);
    background-size: 100%;
    background-position: center;
    height: 110vh;
  }

  .coming__soon-supporters-container-image {display: none;}

  /* PARTNERTS */
  .coming__soon-new-supporters-box {
    /* display: flex; */
    /* flex-direction: column;  */
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
    gap: 3rem;
  }

  .coming__soon-battlecry-img {
    width: 65%;
    height: auto;
    top:20%;
    left: auto;
  }

  .comming__soon-hero-text-box {
    width: 55%;
    left: auto;
    top: 40%;
  }

  .comming__soon-hero-text-box .intro{
    line-height: 39px;
    margin-bottom: 5rem;
    font-size: 1.3rem;
    width: 90%;
    margin: 2rem auto;
  }

  .coming__soon-action-btn-box {
    margin: 3rem auto;
    width: fit-content;
  }

  .comming__soon-hero-text-box .outro{
    margin-left: 28%;
    font-size: 1.1rem;
  }

  #liqair-landing-logo{width: 48%;}

  .coming__soon-assets-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  /* AFRICAN ASSET */
  .coming__soon-assets-section-text{ width: 75%;}

  /* FEATURES */
  .coming__soon-image-background-image{display: none;}

  .coming__soon-features-card-container{
    /* display: flex;
    flex-direction: column; */
  }

  .coming__soon-features-card{
    /* height: 18vh; */
    padding: 2rem 3rem;
    height: auto;
    background-size: 100%;
  }

  .coming__soon-features-card p{
    line-height: normal;
  }

  /* FOOTER */
  .coming__soon-footer-image{display: none;}

  .coming__soon-footer-innner-btn-box{width: 70%;}

  .coming__soon-footer-innner-text{
    padding: 0 5rem;
    line-height: 30px;
  }
  
  /* NAVBAR */
  .landing__navbar{justify-content: unset;}
  .desktop-coming__soon-navbar-request-access{width: 30%;}

  .coming__soon-request-access-modal{
    width: 88%;
  }

  .coming__soon-request-access-modal-image-bg{
    display: none;
  }

  .coming__soon-request-access-modal-inner{
    width: 90%;
    margin: 0 auto;
  }

  .coming__soon-new-request-access-modal{
    width: 80%;
    margin-left: 10%;
  }
}

/* 
    Mobile devices
*/
@media screen and (min-width: 320px) and (max-width: 480px) {
  .coming__soon-footer-image{display: none;}
  .coming__soon-cs-sub-navbar {
    align-items: center;
    flex-direction: column;
  }

  .coming__soon-subnav-btn {
    width: 100%;
  }

  /* battle cry */
  .coming__soon-battlecry-img.mobile {
    width: 90%;
    object-fit: cover;
    /* height: 70vh; */
    left: auto;
    top:20%;
  }

  .coming__soon-battlecry-container {
    overflow-x: scroll;
  }

  
.coming__soon-action-btn-box{
  width: fit-content;
  margin: 2rem auto;
}

.comming__soon-hero-text-box .outro {
  width: auto;
  text-align: center;
}

.comming__soon-hero-text-box {
  width: 65%;
  left: auto;
  top: 50%;
}

.comming__soon-hero-text-box .intro{
  width: 90%;
  margin: auto;
  line-height: 22px;
  font-size: 1.5rem;
}
.comming__soon-hero-text-box .outro{
  margin-left: auto;
  font-size: 1.2rem;
}
.coming__soon-new-supporters-box{
  display: flex;
  flex-direction: column;
}
#liqair-landing-logo{width: 50%;}
#orange-landing-logo{width: 65%;}

.coming__soon-features-card{
  padding: 2rem 3rem;
  height: auto;
  background-size: 100%;
}
.coming__soon-features-card p{
  line-height: 22px;
}

.coming__soon-supporter-card{
  height: 7rem;
  margin-bottom: 2rem;
}

.coming__soon-brand-image {width: 90%;}

.coming__soon-assets-section-text{
  width: 70%;
  line-height: 30px;
}

  /* born */
  .coming__soon-loooty-born-container {
    flex-direction: column;
    align-items: center;
  }

  .coming__soon-assets-container{
    display: flex;
    flex-direction: column;
  }

  .coming__soon-loooty-born-container.inverse {
    flex-direction: column;
  }

  .coming__soon-born-image-container {
    width: 100%;
  }

  .coming__soon-born-body {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-direction: column;
    width: 80%;
  }

  .coming__soon-footer-innner-btn-box{
    width: 50%;
  }

  .coming__soon-footer-innner{padding: 0 2rem;}

  .coming__soon-early-lauch-asset-inner {
    flex-direction: column;
    padding: 0 8rem;
  }

  .coming__soon-features-card-container{
    display: flex;
    flex-direction: column;
  }

  .coming__soon-asset-image-container {
    width: 40%;
    transform: skewX(10deg);
  }

  .coming__soon-footer-innner-text{text-align: center;}

  /* to sell */

  .coming__soon-to-sell-title {
    width: 75%;
    
  }

  .coming__soon-for-everyone {
    
  
  }

  .coming__soon-for-everyone-inner{
    display: flex;
    flex-direction: column-reverse;
  }

  .coming__soon-born-header{
    text-align: center;
  }

  .coming__soon-born-paragraph {

    text-align: center;
  }

  .coming__soon-for-everyone-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .coming__soon-first-container {
    display: flex;
    flex-direction: column;
  }

  .coming__soon-first-container-title {
    width: 100%;
   
   text-align: center;
  }

  .coming__soon-social-medias{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .coming__soon-early-launchers-tag-container{
    padding: 0.7rem 1rem;
    width: 70%;
    display: flex;
    justify-content: center;
  }

  .coming__soon-first-access-container{
    flex-direction: column;

  }

  .coming__soon-request-access-modal{
    width: 97%;
  }
  .coming__soon-request-access-modal-overlay{
    padding: .4rem .3rem;
    align-items: center;
    /* width: 100%; */
    right: 0%;
  }
 
  .coming__soon-new-request-access-modal{
    width: 90%;
    margin-left: 5%;
  }
}


