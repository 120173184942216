.lgn__login-modal {
  width: 30.2vw;
  position: absolute;
  z-index: 3;
  background-color: #38383e;
  top: 200%;
  left: -170%;
  transform: skewX(25deg);
  animation: 0.7s fadeItIn ease-in-out;
}

.lgn__login-modal:active {
  transform: skewX(25deg) scale(1.01);
}

.lgn__fade-out-anim {
  animation: 0.7s fadeItOut ease-in-out !important;
}
@keyframes fadeItIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeItOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.lgn__login-form {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  gap: 1rem;
}

.lgn__form-group {
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
  transform: skewX(-25deg);
  position: relative;
  display: flex;
}

.lgn__hide-sensitive-info-button,
.sign__hide-sensitive-info-button {
  /* position: absolute; */
  background-color: #2a162f;
  padding: 1.05rem 1.5rem;
  right: 1.5%;
  top: 10%;
  display: flex;
  cursor: pointer;
  transform: background-color 0.3s ease-in-out;
}

.lgn__hide-sensitive-info-button:active,
.sign__hide-sensitive-info-button:active {
  transform: scale(1.1);
  background-color: #662077;
}
.lgn__hide-sensitive-ib-icon {
  color: #38383e;
  font-size: 1.2rem;
  transform: skewX(20deg);
}

.sign__hide-sensitive-info-button {
  right: 1.5%;
  top: 2%;
  padding: 0.9rem 1.5rem;
}

.lgn__form-control {
  padding: 1.5rem 2rem;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  transform: skewX(25deg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b09b;
}

.lgn__form-control:focus {
  outline: none;
}
.lgn__form-recovery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-transform: none;
}

.lgn__form-btn-group {
  display: flex;
}

.lgn__login-btn {
  padding: 1.2rem 3rem;
  width: 100%;
  transform: skewX(-25deg);
  background-color: #af23af;
  outline: 2px solid #cf7bcf;
  font-weight: 900;
  outline-offset: -7px;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lgn__login-btn:active {
  transform: scale(1.04) skewX(-25deg);
}

.lgn__login-btn-text {
  transform: skewX(25deg);
}

.lgn__login-alternatives-container {
  background-color: #2a2a30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  /* padding: 2rem 5rem; */
  padding-bottom: 1rem !important;
  gap: 1rem;
}

.lgn__btn-google,
.lgn__btn-facebook {
  background-color: #ffffff;
  border: none;
  padding: 1rem 4rem;
  transform: skewX(-25deg);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1rem;
}

.lgn__btn-google:active,
.lgn__btn-facebook:active {
  transform: scale(1.04) skewX(-25deg);
  display: flex;
}

.lgn__btn-facebook {
  background-color: #1071c1;
  color: white;
}

.lgn__btn-google-text,
.lgn__btn-facebook-text {
  transform: skewX(25deg);
}

.lgn__form-recovery-container {
  padding-top: 1rem;
}

.lgn__arrow {
  width: 35px;
  height: 35px;
  left: 65%;
  top: -2%;
  background-color: #38383e;
  position: absolute;
  transform: rotate(45deg);
}

/* Sign up */

.reg__sign-up-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.729);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.7s fadeItIn ease-in-out;
}
.buypage__overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.729);
  z-index: 20;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  animation: 0.7s fadeItIn ease-in-out;
}
.reg__sign-up-modal {
  background-color: #232230;
  display: flex;
  outline: 3px solid #555155ac;
  outline-offset: 10px;
  width: 50vw;
  height: auto;
  position: relative;
}

.user__withdrawal-modal {
  width: 35vw;
}

.user__withdrawal-modalea {
  width: 25vw;
}

.reg__loooty-innitials-container {
  width: 80%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: white;
}

.reg_logo-container {
  display: flex;
  justify-content: center;
}

.reg__loooty-about {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
  /* width: 60%; */
}

.reg__loooty-features {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  align-items: flex-start;
  gap: 1rem;
  font-size: 1.3rem;
  /* justify-content: center; */
}

.reg__loooty-features-item {
  list-style-type: georgian;
  list-style-image: url('/public/assets/image/logo/logo.svg');
  color: #bcbcbc;
}

.reg__sign-up-forms-container {
  padding: 1rem 1rem;
  background-color: #38383e;
  width: 100%;
}

.reg__sign-up-form {
  gap: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reg__form-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.5rem;
}

.reg__form-group-container p {
  align-self: flex-start;
  padding-left: 1rem;
  margin-top: 0.5rem;
}

.reg__form-group {
  display: flex;
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
  /* #df4759 - bg danger */
  transform: skewX(-25deg);
  transition: 0.4s ease-in-out border;
  /* width: 80%; */
}

.reg__form-control {
  padding: 1rem 2rem;
  /* width: 100%; */
  font-size: 1rem;
  background-color: transparent;
  border: none;
  transform: skewX(25deg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0bf;
}

.reg__form-control::placeholder {
  color: #575763;
}

.reg__form-control:focus {
  outline: none;
}

.reg__form-btn-group {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.reg__close-btn-container {
  display: flex;
  position: absolute;
  right: 0%;
  top: -8%;
}

.reg__close-btn {
  width: 30px;
  height: 30px;
  padding: 1rem 1.5rem;
  transform: skewX(-15deg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #38383e;
  cursor: pointer;
}

.reg__close-btn-text {
  transform: skewX(15deg);
}

.reg__login-btn {
  padding: 1rem 3rem;
  width: 270px;
  margin: auto;
  transform: skewX(-25deg);
  background-color: #af23af;
  outline: 2px solid #cf7bcf;
  font-weight: 900;
  outline-offset: -7px;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reg__login-btn:active {
  transform: scale(1.05) skewX(-25deg);
}

.reg__login-btn-text {
  transform: skewX(25deg);
}

.reg__terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2rem 0;
  gap: 0.3rem;
  font-size: 1rem;
  color: #bcbcbc;
}

.reg__login-alternatives-container {
  background-color: #2e2a30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* padding: 2rem 5rem; */
  padding-bottom: 1rem !important;
  gap: 1rem;
  width: 80%;
}

.reg__btn-google,
.reg__btn-facebook {
  background-color: #ffffff;
  border: none;
  padding: 0.7rem 3rem;
  /*margin-left: 2rem;
  margin-bottom: 2rem;
  /*transform: skewX(-25deg);*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1rem;
  width: 80%;
}

.reg__btn-facebook {
  padding: 0.7rem 1rem;
}

.reg__btn-google:active,
.reg__btn-facebook:active {
  transform: scale(1.04) skewX(-25deg);
  display: flex;
}

.reg__btn-facebook {
  background-color: #1071c1;
  color: white;
}

.reg__btn-google-text,
.reg__btn-facebook-text {
  /*transform: skewX(25deg);*/
}

.reg__form-recovery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-transform: none;
  color: #bcbcbc;
}