.buy__empty-divider {
  height: 3rem;
  width: 100%;
  background-color: #15151c;
  margin-bottom: 2rem;
}

.buy__buy-preview-container {
  background-color: #15151c;
  padding: 1rem 2rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .buy__buy-preview-container {
    overflow-y: scroll;
    padding: 1rem;
    max-width: 100% !important;
  }
}

.buy__buy-preview-container-inner {
  max-width: 100%;
  margin: 0rem auto;
  display: flex;
  gap: 5rem;
  color: white;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .buy__buy-preview-container-inner {
    flex-direction: column;
    padding: 0 2rem;
    max-width: 90% !important;
  }
}

.buy__btn-close-back {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.buy__page-close-btn {
  padding: 0.7rem 1.2rem;
  margin-right: 2.5rem;
  display: flex;
  transform: skewX(-20deg);
  background-color: #38383e;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.buy__page-close-btn:active {
  transform: scale(1.1) skewX(-20deg);
}

.buy__page-close-btn-icon {
  transform: skewX(20deg);
}

.buy__carousel-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 35vw;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .buy__carousel-container {
    width: 100%;
  }
}

.buy__carousel-wrapper {
  position: relative;
  height: 70vh;
}

@media screen and (max-width: 768px) {
  .buy__carousel-wrapper {
    height: 40vh;
  }
}

.buy__property-container {
  display: flex;
  gap: 1rem;
}

.buy__property-inner-container {
  display: flex;
  gap: 0.5rem;
}

.buy__rating-container {
  margin-left: 3rem;
  display: flex;
  gap: 0.1rem;
}

.buy__rating-qty {
  color: #6d6d91;
}

.buy__comments-cont {
  display: flex;
  gap: 0.4rem;
  color: #6d6d91;
}

.buy__tag-arrow {
  transition: transform 0.4s ease-in-out;
}

.buy__product-name {
  color: white;
}

.buy__product-name span {
  color: #6d6d91;
}

.buy__carousel-img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  /* object-position: 50% 30%; */
}

@media screen and (max-width: 768px) {
  .buy__carousel-img {
    height: 40vh;
  }
}

.buy__carousel-preview {
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 1rem;
}

.buy__carousel-preview::-webkit-scrollbar {
  /* display: none; */
  width: 1rem;
  height: 0.5rem;
}

.buy__carousel-preview::-webkit-scrollbar-thumb {
  background-color: #3e3e4f !important;
  width: 4px;
}

.buy__carousel-preview::-webkit-scrollbar-track {
  background-color: #232230 !important;
}

.buy_carousel-preview-img {
  flex: 3;
  width: 100%;
  height: 4rem;
  object-fit: cover;
  object-position: 50% 50%;
}

.buy__file-purchase-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-start;
}

.buy__file-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buy__file-attributed-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.buy__file-attributed-body {
  display: flex;
  color: #616181;
  font-size: 0.9rem;
  gap: 0.8rem;
  align-items: center;
}

.buy__file-attributed-img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 100%;
}

.buy__comment-header {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.buy__comment-divider {
  transform: skewX(-25deg);
  width: 1rem;
  height: 100%;
  background-color: #232230;
}

.buy__comment-tab-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.buy__comment-description {
  width: 30vw;
  color: #6d6d91;
  display: flex;
  flex-direction: column;
}

.buy__read-more {
  color: #af23af;
  cursor: pointer;
}

.buy__tags-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buy__tag-header {
  display: flex;
  gap: 0.4rem;
  cursor: pointer;
}

.buy__tag-select {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 50%;
  gap: 0.5rem;
}

.buy__tag-btn {
  background-color: #232230;
  padding: 0.4rem 0.5rem;
  border: transparent;
  color: #636384;
}

.buy__cart-buy-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buy__cart-buy-header {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.buy__cart-purchase-main-container {
  background-color: #232230;
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
}

.buy__purchase-btn-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.buy__purchase-rating {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 40%;
  background-color: #44434f;
  color: #d1d1d1;
  /* transform: skewX(-25deg); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.buy__purchase-ratebox {
  border: 1px solid #5a5964;
  padding: 0 0.4rem;
  font-size: 2rem;
}

.buy__purchase-prices {
  position: absolute;
  top: 100%;
  right: 15%;
  width: 50%;
  background-color: rgb(68, 67, 79);
  color: #d1d1d1;
  /* transform: skewX(-25deg); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
}
@media only screen and (max-width: 480px) {
  .buy__purchase-prices {
    width: 88%;
    flex-direction: row;
  }
}
.buy__price {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}
.buy__price:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.buy__price span {
  font-weight: 600;
}
.buy__purchase-btn {
  padding: 1rem 10rem;
  background-color: #5cb85c;
  color: #ffffff;
  transform: skewX(-20deg);
  outline: 2px solid #9dd49d;
  outline-offset: -6px;
  display: flex;
  border: transparent;
  position: relative;
  cursor: pointer;
}

.buy__purchase-btn:disabled {
  cursor: not-allowed;
}

.buy__btn-hover-price {
  background-color: #4c934c;
  color: white;
  position: absolute;
  right: 3.5%;
  top: 25.5%;
  padding: 0.3rem 0.8rem;
}

.buy__heart-btn-container {
  background-color: #44434f;
  width: 3rem;
  height: 2rem;
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9dde0;
  font-size: 1.3rem;
  transform: skewX(-20deg);
  cursor: pointer;
}
.paystack-logo {
  width: 2rem;
}
.buy__payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 70%;
}

.buy__payment-options {
  display: flex;
  gap: 1rem;
}

.buy__payment-options-logos {
  width: 2rem;
  background-color: #5a5964;
  height: 2rem;
  padding: 0.3rem;
}

.buy_socials-container {
  display: flex;
  gap: 1rem;
  /* margin-left: 6rem; */
}

.buy__social-btn {
  display: flex;
  /* width: 4rem; */
  padding: 0.5rem 0.7rem;
  gap: 1.1rem;
  color: white;
  border: transparent;
  align-items: center;
}

.buy__socials-logo {
  font-size: 1.3rem;
}

.buy__copy-url-form {
  display: flex;
  justify-content: center;
}

.buy__copy-url-form-group {
  display: flex;
  position: relative;
  align-items: center;
  border: 0.3rem solid #2b2b38;
  transform: skewX(-25deg);
  gap: 0.6rem;
  /* padding: .6rem; */
  /* width: 55%; */
  /* margin-left: 6rem; */
}

.buy__copy-url-form-control {
  padding: 0.7rem 1rem;
  background-color: transparent;
  color: white;
  border: none;
  transform: skewX(25deg);

  /* width: 100%; */
}

.buy__copy-url-form-control:focus {
  outline: none;
}

.buy__copy-url-form-control::placeholder {
  color: #38383e;
  font-size: 0.8rem;
}

.buy__copy-url-btn {
  background-color: #38383e;
  /* transform: skewX(-25deg); */
  font-size: 0.7rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 2rem;
  height: 2.3rem;
  border: none;
  /* position: absolute; */
  /* top: 11%;
    right: 1.2%; */
  cursor: pointer;
}

.buy__copy-url-btn-text {
  transform: skewX(25deg);
}

.buy__copy-url-btn:active {
  transform: scale(1.05);
}

/* Comments */

.buy__comments-main-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  height: 62.5vh;
}

.buy__comments-main-container::-webkit-scrollbar {
  display: none;
}

.buy__comm-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buy__comm-first-layer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.buy__comment-date {
  font-size: 0.8rem;
  color: #747482;
}
.buy__customer-tag {
  display: flex;
  padding: 0.3rem 1rem;
  background-color: #7c187a;
  transform: skewX(-20deg);
  border: transparent;
  color: #e9f2f4;
}

.buy__user-profile-img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.buy__author-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #bfc6c9;
}

.buy__comm-second-layer {
  display: flex;
}

.buy__main-comm {
  color: #6d6d91;
}

.buy__send-comment-container {
  display: flex;
  gap: 1rem;
}

.buy__comments-listing-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.buy__comm-third-layer {
  color: #6d6d91;
}

.buy__reactions-container {
  display: flex;
  gap: 3rem;
}

.buy__like-cont {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.buy__dislike-cont {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.buy__comment-reply {
  color: #af23af;
}