.sidebar {
  background-color: #1b1a24;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.3rem 0.5rem;
  flex: 6 6 6;
  transition: width 1s ease-in-out;
  z-index: 12;
  animation: 0.5s fadeInSidebar ease-in-out;
  height: 100%;
  width: 25%;
}

.sidebar__inner-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 999;
}

.sidebar__inner-container::-webkit-scrollbar {
  display: none;
}

@keyframes fadeInSidebar {
  0% {
    opacity: 0;
    width: 1rem;
  }

  100% {
    opacity: 1;
    width: 25%;
  }
}

.fade-out-sidebar {
  animation: 0.69s fadeOutSidebar linear;
}

@keyframes fadeOutSidebar {
  0% {
    opacity: 1;
    width: 20%;
  }

  100% {
    width: 0%;
    opacity: 0;
  }
}

.sidebar__auth-sec {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* position: sticky;
    top: 0%; */
}

.sidebar__tags {
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* position: sticky;
    top: 20%; */
}

.sidebar__footer {
  padding: 3rem;
  color: #ceced8;
  /* position: sticky;
    top: 60%; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sidebar__top {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
}

.sidebar__socials {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar__socials a {
  color: #ceced8;
  display: flex;
  gap: 1rem;
}

.sidebar__socials-item {
  display: flex;
  gap: 1rem;
}

.sidebar__bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar__bottom a {
  color: #ceced8;
}

.sidebar__bottom-item {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 768px) {
  .sidebar {
    width: 70%;
  }
}
