.app__checkbutton {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s border ease-in-out, 0.3s border-radius ease-in-out;
}

.activate-round {
  border-radius: 100%;
}

.app__checkbutton:active {
  border: 0.2rem solid #ff9700;
  border-radius: 100%;
}

.app__checkbutton-active {
  background-color: #ff9700;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: 0.3s fade-active ease-in-out;
}

/* .loooty__loader-container{
    width: 100%;
    height: 100%; 
     background-color: red; 
} */

.loooty__loader {
  width: 4rem;
  height: 1rem;
  object-fit: cover;
}

@keyframes fade-active {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
