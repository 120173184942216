/* Buttons */

.composite__btn-container {
  display: flex;
}
.composite__btn {
  background-color: #ff9700;
  color: white;
  border: none;
  padding: 1rem 8rem;

  transform: skewX(-25deg);

  outline: 2px solid #ffc166;
  outline-offset: -5px;
  display: flex;
  cursor: pointer;
}

.composite__btn:active {
  transform: skewX(-25deg) scale(1.05);
}

.composite__btn-text {
  transform: skewX(25deg);
}

/* Search Bar */

.search__skew-container {
  display: flex;
  animation: fadeInAnimation 0.5s ease-in-out;
  height: 5vh;
}

.search__nav-search-skew-container {
  display: flex;
  animation: fadeInAnimation 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search__group-container {
  position: relative;
  transform: skewX(-25deg);
  /* width: 35vw; */
  padding: 0.1rem 0;
  padding-right: 0.1rem;
  width: 100%;
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
  display: flex;
}

.search__btn {
  position: relative;
  background-color: #38383e;
  color: white;
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  border: none;
  align-self: center;

  display: flex;
  cursor: pointer;
}

.search__btn:active {
  transform: scale(1.05);
}

.search__nav-search-btn {
  position: relative;
  background-color: #38383e;
  color: white;
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  border: none;

  display: flex;
  cursor: pointer;
}

.search__nav-search-btn:active {
  transform: scale(1.05);
}

.search__input {
  border: none;
  padding: 0 2rem;
  background-color: transparent;
  transform: skewX(25deg);
  width: 400px;
  max-width: 100%;
  color: white;
}

.search__input:focus {
  outline: none;
}

/* Identity btn */

.identity__btn-skew-container {
  display: flex;
  z-index: 1;
}

.identity__btn {
  padding: 1rem 2rem;
  font-size: 0.9rem;
  background-color: #15151c;
  justify-content: center;
  align-items: center;

  transform: skewX(-25deg);
  color: white;
  border: none;
  border-left: 1rem solid #7c187a;
  display: flex;
  position: relative;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  transition: margin 0.5s ease-in-out, border 0.3s ease-in-out,
    background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.identity__btn:active {
  transform: skewX(-25deg) scale(1.05);
}

.identity__btn-text {
  transform: skewX(25deg);
  display: flex;
  gap: 0.5rem;
}

.identity__btn-bg--dark {
  background-color: #15151c;
}

.identity__btn-bg--light {
  background-color: #232230;
}

.identity__btn-dropdown {
  background-color: #38383e;
  transform: skewX(25deg);
  position: absolute;
  bottom: -200%;
  left: 5%;
  width: 12rem;
  color: white;
  z-index: +30;
}

.identity__btn-dropdown-item {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.identity__btn-dropdown-item:active {
  padding: 1rem;

  background-color: #15151c;
}

.identity__btn-dropdown-item:hover {
  transform: scale(1.02);
}

.identity__btn-inner-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
}

.identity__btn-container {
  height: 7vh;
  background-color: #232230;
}

.dropdown {
  position: relative;
  width: 100%;
  background-color: transparent;
  display: flex;
}
.dropdown__header {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid #2a2a37;
  background-color: rgb(21, 21, 28);
  color: #fff;
  gap: 7px;
  padding: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  transform: skewX(-20deg);
  width: 100%;
}
.dropdown__content {
  position: absolute;
  top: 120%;
  right: 0;
  left: 0;
  width: 100%;
  background-color: #2b2b38;
  box-shadow: 2px 8px 11px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  border-radius: 5px;
  z-index: 999;
  max-height: 300px;
  font-size: 1rem;
  overflow-y: scroll;
}
.dropdown__content li {
  font-weight: 500;
  text-align: center;
  padding: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  border-bottom: 1px solid #38383e;
}
.dropdown__content li:hover {
  background-color: #38383e;
}

.activeItem {
  background-color: #15151c;

  border-radius: 3px;
  color: #ff9700;
}
