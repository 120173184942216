.landing__main-app {
  display: flex;
  grid-template-columns: 20% 80%;
  overflow: visible;
  gap: 3rem;
}

.landing__app-container {
  position: relative;
  width: 100%;
  flex: 6 6 6;
  transition: width 1s ease-in-out;
  /* overflow-y: scroll; */
}

.logo__anim {
  transition: transform 0.3s ease-in-out;
}

.landing__overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.596);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 999;
}

.landing__overlay-dismiss {
  animation: 0.7s overlayFadeOut ease-in-out;
}

@keyframes overlayFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* .logo__hide-in{
    opacity: 0;
} */

/* .logo__in{
    animation: 1s hideIn ease-in-out;

} */

@keyframes hideIn {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-5rem);
  }
}

/* .logo__out{
    animation: 1s hideOut ease-in-out;

} */

@keyframes hideIn {
  0% {
    transform: translateX(0);
  }

  90% {
    transform: translateX(-4rem);
  }

  100% {
    transform: translateX(-5rem);
    visibility: hidden;
  }
}

/* All header styles */
.landingpage__header {
  background-color: #7c187a;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;

  /* Set the initial background image */
}

/* Define the keyframes for changing background images */
@keyframes changeBackground {
  0% {
    background-image: url('/public/assets/image/homepage.png');
  }
  35% {
    background-image: url('/public/assets/image/spoiltroad.jpg');
  }
  75% {
    background-image: url('/public/assets/image/loootydefault.jpg');
  }
  100% {
    background-image: url('/public/assets/image/spoiltroad.jpg');
  }
}

.slider .slide:nth-child(1) {
  background-image: url('https://images.unsplash.com/photo-1595356700395-6f14b5c1f33f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
  animation-delay: 0s;
}

.slider .slide:nth-child(2) {
  background-image: url('https://images.unsplash.com/photo-1591019052241-e4d95a5dc3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
  animation-delay: -2.5s;
}

.slider .slide:nth-child(3) {
  background-image: url('https://images.unsplash.com/photo-1543373014-cfe4f4bc1cdf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
  animation-delay: -5s;
}

.slider .slide:nth-child(4) {
  background-image: url('https://images.unsplash.com/photo-1517430554953-a5ba4678fe85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
  animation-delay: -7.5s;
}

.slider .slide:nth-child(5) {
  background-image: url('https://images.unsplash.com/photo-1583161178154-c362b3459d29?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
  animation-delay: -10s;
}

@keyframes slider {
  0%,
  16%,
  100% {
    transform: translateX(0);
    animation-timing-function: ease;
  }
  20% {
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  96% {
    transform: translateX(100%);
    animation-timing-function: ease;
  }
}

@keyframes slide {
  0%,
  100% {
    background-image: url('/public/assets/image/homepage.png');
  }
  25% {
    background-image: url('/public/assets/image/spoiltroad.jpg');
  }
  50% {
    background-image: url('/public/assets/image/loootydefault.jpg');
  }
  75% {
    background-image: url('/public/assets/image/loootydefault.jpg');
  }
}
.landing__navbar {
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  /* max-width: 110rem; */
  /* width: 100%; */
  top: 0%;
  left: 0%;
  z-index: 30;
  transition: background-color 0.4s ease-in-out, width 0.4s ease-in-out,
    margin 1s ease-in-out;
}

.landing__navbar-mobile-filter-box,
.landing__navbar-mobile-search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  align-items: center;
}
.landing__navbar-main-box {
  height: 7vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  align-items: center;
}

.landing__navbar-mobile-filter-box {
  display: none;
}

.landing__sidebar-toggler {
  z-index: 10;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  position: absolute;
  left: 10px;
}

.landing__sidebar-toggler:active {
  transform: scale(1.1);
  opacity: 0;
}
.navbar-mobile-filter-search-container {
  display: none;
  background-color: '#15151C';
  flex-direction: 'column';
  width: '100%';
  z-index: 50 !important;
}

.landing__navbar-brand {
  font-size: 1.4rem;
  text-decoration: none;
}

.nav_filter-dropdown {
  background-color: rgb(35, 34, 48);
  padding: 0.8em 0.6rem;
  width: 100%;
}
.mobile-library_filter-dropdown {
  padding: 0.8em 0.6rem;
  width: 100%;
  border: 0.4px solid #6d6d91;
}
.navbar__dropdown-filter-box {
  text-align: center;
}
.navbar__dropdown-filter-box ul {
  background-color: transparent;
  outline: none;
  border: none;
}
.nav_mobile_filter-options-container,
.nav_mobile_filter-sub-options-container {
  flex-direction: column;
  background-color: rgb(35, 34, 48);
  width: 98%;
  padding: 1rem 0.8rem;
  color: #6d6d91;
  margin-top: 1rem;
}
.nav_mobile_filter-sub-options-container {
  position: absolute;
  top: 5rem;
  left: 20%;
  width: 60%;
  margin: auto;
  padding: 2rem 2.5rem;
  background-color: #15151c;
  color: #fff;
  border-radius: 10px;
}
.navbar__dropdown-filter-item {
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  border-bottom: 0.4px solid #6d6d91;
}
.navbar__dropdown-filter-sub-item {
  border-bottom: none;
  padding: 1rem 2rem;
  font-size: 1rem;
}
.mobile-library__dropdown-filter-item {
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  border-bottom: 0.4px solid #6d6d91;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar__dropdown-filter-item:hover {
  color: #ff9700;
}

.landing__navbar-inner-container {
  display: flex;
  gap: 0.8rem;
  /* width: 20%; */
}

.landing__nav-item {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff3d;
  border: 2px solid transparent;
  padding: 0.5rem 1.5rem;
  transform: skewX(-25deg);
  color: #fff;
  transition: 0.4s ease-in-out border, 0.4s ease-in-out background-color;
  display: flex;
  position: relative;
  z-index: 3;
}

.landing__nav-item:active {
  transform: skewX(-25deg) scale(1.05);
}

.landing__nav-item.signup {
  background-color: #7c187a;
  padding: 0.5rem 3rem;
}

.landing__nav-item.signup:hover {
  background-color: #7c187a;
}

.landing__nav-item.login {
  background-color: #ffffff3d;
  padding: 0.5rem 3rem;
}

.landing__nav-item.login:hover {
  background-color: #7c187a;
}

.landing__nav-item.login:active {
  transform: skewX(-25deg) scale(1);
}

.landing__nav-item.logout {
  outline: 2px solid #3d3c45;
  background-color: #3d3c45;
  outline-offset: 4px;
  margin-top: 0;
}

.landing__nav-item:hover {
  background-color: #ffffff3f;
  border: 2px solid #a3a3a3;
  cursor: pointer;
}

.landing__navbar-inner-container-li {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  gap: 1rem;
  width: 20%;
  position: absolute;
  right: 0;
}

.landing__navbar-logo-items {
  color: white;
  cursor: pointer;
  transition: 0.4s color ease-in;
  position: relative;
  font-size: 1.5rem;
}

.landing__navbar-logo-items:hover {
  color: #7e8993;
}

/* notification modal */
.landing__notification-modal {
  display: flex;
  flex-direction: column;
  background-color: #302f42;
  position: absolute;
  width: 25rem;
  left: -1400%;
  top: 200%;
}

.landing__notifier-arrow-up {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: -3%;
  left: 84%;
  background-color: #302f42;
  /* background-color: red; */
  z-index: 0;
  transform: rotate(45deg);
}

.landing__notification-body {
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 0.4rem;
}

.landing__notification-item {
  display: flex;
  padding: 0.8rem 2rem;
  gap: 1rem;
  background-color: #38374a;
  border: 0.2rem solid transparent;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.landing__notification-item.selected {
  border: 0.2rem solid #626171;
}

.landing__notification-item:active {
  background-color: #919198;
}
.landing__notification-img-container {
  width: 4rem;
  height: 4rem;
}

.landing__notification-img {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  object-fit: cover;
}

.landing__notification-body {
  display: flex;
  flex-direction: column;
}

.landing__notification-text {
  color: white;
  word-wrap: break-word;
  font-size: 1rem;
}

.landing__notification-sub {
  color: #646482;
  font-size: 0.9rem;
}

.landing__notification-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #302f42;
}

.landing__notification-footer:active {
  background-color: #919198;
}
/* notification modal end */

.landing__navbar-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.landing__navbar-profile-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #44434f;
  padding: 0.5rem;
  transform: skewX(-20deg);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.landing__navbar-profile-img-container:hover {
  background-color: #38383e;
  cursor: pointer;
}

.landing__navbar-profile-img {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  transform: skewX(20deg);
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
}

.landing__navbar-user-name {
  color: #7e8993;
  cursor: pointer;
}

.landing__sidebar-toggle {
  cursor: pointer;
  /* transition: transform .5s ease-in-out; */
}

.landing__sidebar-toggle-close {
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 26%;
  /* transition: transform .5s ease-in-out; */
}

/* .landing__sidebar-toggle:active{
    transform: scale(1.1);
} */

.landing__nav-link {
  color: #fff !important;
  transform: skewX(25deg);
  font-size: 0.8rem;
}

.landing__slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7rem;
  z-index: -1;
  height: 100%;
}

.landing__slogan-first-text {
  color: white;
}

.landing__slogan-second-text {
  font-size: 2rem;
  color: white;
  margin-bottom: 2rem;
}

.landing__instruct-btn-container {
  display: flex;
  gap: 1rem;
}

.landing__slogan-browse {
  background-color: #7c187a;
  color: white;
  border: none;
  padding: 1rem 8rem;
  display: flex;
  transform: skewX(-25deg);
  cursor: pointer;
}

.landing__slogan-browse:active {
  transform: skewX(-25deg) scale(1.05);
}

.landing__slogan-sell {
  background-color: #ff9700;
  color: white;
  border: none;
  padding: 1rem 8rem;

  transform: skewX(-25deg);

  outline: 2px solid #ffc166;
  outline-offset: -5px;
  display: flex;
  cursor: pointer;
}

.landing__slogan-sell:active {
  transform: skewX(-25deg) scale(1.05);
}

.landing__slogan-browse-text,
.landing__slogan-sell-text {
  transform: skewX(25deg);
}

/* header stops here */

/* Main section starts here */
.landing__main {
  background-color: #232230;
}

.landing__search-section {
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100rem;
  margin: 0 auto;
}

.landing__search-skew-container {
  display: flex;
}

.landing__search-group-container {
  position: relative;
  transform: skewX(-25deg);
  width: 40vw;
  background-color: #15151c;
  border: 0.2rem solid #2b2b38;
}

.landing__search-btn {
  position: absolute;
  background-color: #38383e;
  color: white;
  font-size: 1.3rem;
  padding: 0.3rem 0.7rem;
  border: none;
  right: 0.5%;
  top: 12%;
  display: flex;
  cursor: pointer;
}

.landing__search-btn:active {
  transform: scale(1.05);
}

.landing__search-stuff {
  width: 94%;
  border: none;
  padding: 1rem 12rem;
  background-color: transparent;
  transform: skewX(25deg);
  color: white;
}

.landing__search-stuff:focus {
  outline: none;
}

.landing__weekly-freebies-container {
  color: white;
  align-self: flex-start;
  justify-self: flex-end;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: flex-end;
  gap: 45%;
  max-width: 90rem;
  margin: 0 auto;
  padding-left: 1.5rem;
  background-color: #232230;
}

.landing__weekly-freebies-btn {
  background-color: #15151c;
  color: white;
  border: none;
  padding: 1rem 4rem;
  transform: skewX(-25deg);
  font-size: 0.8rem;
  border-left: 1rem solid #7c187a;
  display: flex;
}

.landing__daily-picks-filters-container {
  display: flex;
  gap: 0.5rem;
}

.landing__daily-picks-filters-btn {
  background-color: #1d1d28;
  color: #3e3e4f;
  border: none;
  padding: 1rem 3rem;
  transform: skewX(-25deg);
  font-size: 0.8rem;
  /* border-left: 1rem solid #7C187A; */
  display: flex;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.landing__daily-picks-filters-btn:active {
  transform: scale(1.05) skewX(-25deg);
}

.landing__show-filters-container {
  background-color: #1b1a24;
  height: 10vh;
  display: flex;
  align-items: flex-end;
  /* justify-content: flex-end; */
}

.landing__show-filters-inner-container {
  display: flex;
  justify-content: flex-end;
  max-width: 90rem;
  margin: 0 auto;
  border-bottom: 0.3rem solid #15151c;
  position: relative;
  width: 100%;
  /* height: 100%; */
}

.landing__show-filters-btn {
  position: relative;
  right: -0.8%;
  bottom: 0%;
  background-color: #15151c;
  color: #3e3e4f;
  border: none;
  padding: 1rem 3rem;
  transform: skewX(-25deg);
  font-size: 0.8rem;
  /* border-left: 1rem solid #7C187A; */
  display: flex;
  transition: 0.3s color ease-in-out, 0.3s transform ease-in-out;
}

.landing__show-filters-btn:hover {
  color: white;
  cursor: pointer;
}

.landing__show-filters-btn:active {
  transform: scale(1.05) skewX(-25deg);
}

.landing__freebies-inner-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  max-width: 90rem;
  margin: 1rem auto;
}

.landing__filter-sytem-container {
  background-color: #1b1a24;
  width: fit-content;
}

.landing__filter-system-inner-container {
  display: flex;
  max-width: 95rem;
  margin: 0 auto;
  flex-direction: column;
  gap: 2rem;
  color: white;
  padding: 1rem 0;
  justify-content: center;
  /* align-items: center; */
}

.landing__filter-headers {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}

.landing__filter-header-item-active,
.landing__filter-header-item {
  color: #353543;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: transparent 0.2rem solid;
  transition: border-bottom 0.3s ease-in-out, color 0.3s ease-in-out;
}

.landing__filter-header-item-active {
  color: #abafb4;
  border-bottom: #771876 0.2rem solid;
}

.landing__filter-system {
  display: flex;
  gap: 0.3rem;
}

.landing__filter-component-container {
  background-color: #232230;
  padding: 0.4rem 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  transform: skewX(-20deg);
}

.landing__filter-component-title {
  color: #353543;
  transform: skewX(20deg);
}

.landing__filter-component-body {
  color: #cbd0d4;
  gap: 0.2rem;
  background-color: #15151c;
  padding: 0.7rem 1rem;
  border: 0.2rem solid #2b2b38;
}

.landing__filter-dropdown-skew-container {
  transform: skewX(20deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__filter-slider-container {
  display: flex;
  background-color: #232230;
  transform: skewX(-20deg);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1rem;
  gap: 0.5rem;
}

.landing__filter-slide-price {
  transform: skewX(20deg);
}

.landing__filter-main-slider {
  position: relative;
  transform: skewX(20deg);
  width: 12rem;
  display: flex;
  align-items: center;
  margin: 0 2rem;
}

.landing__filter-slider-max {
  position: absolute;
  width: 100%;
  /* right: 2%; */
  /* transform: scaleX(1) skewX(-40deg); */
  /* writing-mode: bt-lr; */
}

.landing__filter-slider-min {
  position: absolute;
  /* left: 2%; */
  width: 100%;

  /* transform: scaleX(1) skewX(-40deg); */
  background-color: red;
}

.landing__filter-slider-min::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  /* background: #7AA41D; */
  /* height: 0.5rem; */
  /* opacity: 0; */
}

.landing__filter-slider-min::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: transparent !important;
  background-image: url('/public/assets/image/slider/slider-head.png');
  object-fit: cover;
  transform: scaleX(1) skewX(-20deg) !important;
  z-index: 100 !important;
  /* height: 0.5rem; */
  /* opacity: 0; */
}

.landing__filter-slider-max::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  /* background: #7AA41D; */
  /* height: 0.5rem; */
}

.landing__filter-slider-max::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: transparent !important;

  z-index: 100 !important;

  /* background: transparent!important; */
  background-image: url('/public/assets/image/slider/slider-head.png');
  object-fit: cover;
  transform: scaleX(1) skewX(-20deg) !important;

  /* height: 0.5rem; */
  /* opacity: 0; */
}

.landing__filter-rating-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232230;
  transform: skewX(-20deg);
  padding: 0.4rem 1rem;
  gap: 0.5rem;
}

.landing__filter-rating-container span {
  transform: skewX(20deg);
  color: #3c3c4d;
}

.landing__filter-inner-rating {
  display: flex;
  transform: skewX(20deg);

  gap: 0.2rem;
  margin-right: 1.7rem;
}

.landing__filter-inner-rating-rate {
  color: #6d6d91;
}

/* 
Filter slider start
*/

.landing__slide-range_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 35% auto;
}

.landing__slide-sliders_control {
  position: relative;
  min-height: 50px;
}

.landing__slide-form_control {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  color: #635a5a;
}

#fromSlider {
  height: 0;
  z-index: 1;
}

/* Main slider */
.slider {
  position: relative;
  z-index: 1;
  height: 10px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #c6aee7;
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #6200ee;
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  background-color: #6200ee;
  border-radius: 50%;
  opacity: 0.3;
}

.slider > .thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
}

.slider > .thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
}

input[type='range'] {
  position: absolute;
  z-index: 4;
  height: 10px;
  width: 100%;
}

#input-left {
  top: -100px;
}

#input-right {
  top: -50px;
}
/* 
FIlter slider end 
*/
/* landing card starts here */
.landing__card {
  position: relative;
  background-color: #232230;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  height: 36vh;
}

.landing__card-userStore {
  position: relative;
  background-color: #232230;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  height: 32vh;
  margin-bottom: 1rem;
}

.landing__card:active {
  transform: scale(1.05);
}

.landing__card-whiteList {
  width: 4rem;
  position: absolute;
  animation: fade-in 0.3s ease-in-out;
  z-index: +1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.landing__card:hover {
  background-color: #38384c;
  box-shadow: 8px 12px 7px rgba(0, 0, 0, 0.637);
}

.landing-card-img {
  width: 100%;
  height: 25vh;
  object-fit: cover;
  cursor: pointer;
}

.landing__card-body {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.7rem;
  gap: 0.3rem;
  cursor: pointer;
}

.landing__product-title {
  color: white;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing__product-title-affirm {
  font-size: 0.8rem;
  color: #6d6d91;
  margin-bottom: 2px;
}

.landing__product-author {
  color: #6d6d91;
  transition: color 0.3s ease-in-out;
}

.landing__rating-container {
  display: flex;
  align-items: center;
  position: relative;
}

.landing__card-stats-container {
  display: flex;
  gap: 0.5rem;
}

.landing__card-stats-views {
  color: #6d6d91;
  font-size: 1rem;
}

.landing__card-stats-comments {
  font-size: 1rem;
  color: #6d6d91;
  display: flex;
  gap: 0.1rem;
}

.landing__price {
  position: absolute;
  justify-self: flex-end;
  color: #d2d7db;
  margin-left: 15%;
  right: 2%;
  bottom: 22%;
  font-size: 0.9rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  z-index: +2;
}

/* Rating */
.landing__star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing__rating-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.landing__rating-on {
  color: #ff9700;
}
.landing__rating-off {
  color: #000000;
}

.landing__rating-active {
  color: #ff9700;
}

.landing__cg-text {
  /* height: 14vh; */
  text-align: center;
  padding: 0.4rem;
  font-weight: 800;
  /*transform: skewX(17deg);*/
}

.landing__cg-text-slim {
  /* height: 14vh; */
  text-align: center;
  padding: 0.4rem;
  font-weight: 800;
  /*transform: skewX(17deg);*/
}

.landing__cg-card-outer-container {
  background-color: #1b1a24;
  width: 100vw;
}

.landing__cg-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 100vw;
  overflow-x: scroll;
  padding: 2rem 2rem 1rem;
  margin-bottom: 0rem;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* Set the background color of the track */
  }

  &::-webkit-scrollbar-thumb {
    background: #373744;
    border-radius: 50px;
    height: 1em;
  }
}

.landing__cg-card-container-slim {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  top: 6vh;
  width: 100vw;
  z-index: 12;
  background-color: #15151c;
  animation: slide-down 0.7s ease-in-out;
  overflow-x: auto;
}

@keyframes slide-down {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.landing__cg-card {
  display: flex;
  flex-direction: column;
  /*transform: skewX(-17deg);*/
  position: relative;
  cursor: pointer;
  height: 50px;
  color: #fff;
  font-weight: 600;
}

.landing__cg-card-slim {
  display: flex;
  /*transform: skewX(-17deg);*/
  position: relative;
  cursor: pointer;
}

.landing__cg-img {
  width: 12rem;
  height: 50px;
  object-fit: cover;
}

.landing__cg-img-slim {
  width: 12rem;
  height: 3rem;
  object-fit: cover;
}

.landing__cg-overlay-container {
  position: absolute;
  color: #b3babe;
  background-color: rgba(0, 0, 0, 0.482);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.3rem solid #2c2c39;
}

.landing__cg-overlay-container-slim {
  position: absolute;
  color: #b3babe;
  background-color: rgba(0, 0, 0, 0.482);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.3rem solid #2c2c39;
  gap: 0.5rem;
}

.landing__filters {
  height: 15vh;
}

.landing__all-products-container {
  background-color: #1b1a24;
  /* display: flex;
    flex-direction: column;
    gap: 1rem; */
  padding: 2rem 0;
}

.landing__all-products-inner-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  max-width: 90rem;
  margin: 0 auto;
}

.landing__filter-tag-container {
  height: 10vh;

  background-color: #1b1a24;
}

.landing__filter-tag-inner-container {
  max-width: 90rem;
  margin: 0 auto;
  padding-bottom: 0.7rem;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.landing__filter-tags-btn {
  background-color: #2c2c3d;
  color: white;
  padding: 1rem 4rem;
  margin-bottom: 0.3rem;
  transform: skewX(-25deg);
  /* width: 20vw; */
  font-size: 0.8rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s transform ease-in-out;
  /* border-left: 1rem solid #7C187A ; */
}

.landing__filter-tags-btn-text {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
}

.landing__filter-tags-btn:active {
  transform: skewX(-25deg) scale(1.05);
}

.landing__start-selling {
  background-color: #232230;
  padding-top: 4rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.landing__start-selling-container {
  background-image: url('/public/assets/image/communitysell.png');
  height: 40vh;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.landing__latest-collection-container {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
}

.landing__start-selling-text {
  font-size: 2rem;
}

.landing__start-selling-desc {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.landing__double-card {
  display: flex;
  flex-direction: column;
}

.landing__images-container {
  display: flex;
}

.landing__double-img-normal {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.landing__double-img-grey {
  width: 100%;
  height: 30vh;
  filter: grayscale(100%);
  object-fit: cover;
}

.landing__double-text-container {
  display: flex;
  background-color: #232230;
  padding: 1rem 0;
  justify-content: space-around;
}

.landing__double-text-header {
  font-size: 1rem;
}

.landing__double-text-desc {
  color: #6d6d91;
}

.landing__latest-collection-cont {
  color: white;
  background-color: #1b1a24;
}

.landing__double-inner-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  height: 43vh;
  margin: 0 auto;
  max-width: 100rem;
  padding: 2rem 4rem;
}

.landing__browse-all-products-container {
  background-color: #1b1a24;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
}

.landing__browse-all-products-btn {
  background-color: #2c2c3d;
  color: white;
  padding: 1rem 2rem;
  font-size: 0.7rem;
  border: none;
  transform: skewX(-25deg);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.landing__browse-all-products-btn:active {
  transform: skewX(-25deg) scale(1.05);
}

.landing__browse-all-products-btn-text {
  transform: skewX(25deg);
  display: flex;
  /* font-size: 1rem; */
}

.landing__earn-more-container {
  height: 10vh;
  background-color: #232230;
}

.landing__earn-more-container-inner {
  /* height: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 90rem;
  margin: 0 auto;
}

.landing__earn-more-btn {
  padding: 1rem 2rem;
  font-size: 0.7rem;
  background-color: #15151c;
  transform: skewX(-25deg);
  color: white;
  border-left: 1rem solid #7c187a;
  display: flex;
}

.landing__earn-more-btn-text {
  transform: skewX(25deg);
}
/* 
.landing__open-partner-container {
  background-color: red;
} */

.landing__open-partner-inner-container {
  display: flex;

  position: relative;
}

.landing__open-img-container {
  height: 75vh;
  width: 100%;
  position: relative;
  /* transform: skewX(-25deg); */
  display: flex;
}

.landing__open-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 5%;
  position: relative;
  left: 15%;
  /* transform: skewX(25deg); */
}

.landing__img-overlay-open {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.596);
  left: 15%;
  position: absolute;
  animation: 0.4s fadeIn ease-in-out;
}

.landing__partner-img-container {
  height: 75vh;
  width: 100%;
  position: relative;
  display: flex;
}

.landing__partner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 1% 25%;
  position: relative;
  right: 15%;

  clip-path: polygon(100% 100%, 0 100%, 0 100%, 30% 0, 100% 0);
}

.landing__img-overlay-partner {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.596);
  right: 15%;
  position: absolute;
  clip-path: polygon(100% 100%, 0 100%, 0 100%, 30% 0, 100% 0);
  animation: 0.4s fadeIn ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.landing__open-partner-label-container {
  width: 70vw;
  height: 20vh;
  position: absolute;
  top: 30%;
  left: 10%;
  display: flex;
  color: white;
  transition: left 0.5s ease-in-out;
}

.landing__bg-label-partner {
  cursor: pointer;
  padding-left: 8%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  left: -5%;
  /* top: .1%; */
  position: relative;
  /* height: 99.9%; */
  height: 12.1rem;

  opacity: 0.8;
  color: white;
  background-color: black;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

/* .landing__bg-label-partner:active {
    background-color: blue;

} */

.landing__bg-label-open {
  padding-right: 8%;
  color: black;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  flex-direction: column;
  width: 90%;
  right: 15%;
  background-color: white;
  position: relative;
  /* height: 100%; */
  height: 12.1rem;

  clip-path: polygon(100% 100%, 0 100%, 0 100%, 10.5% 0, 100% 0);
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.landing__open-label-header,
.landing__partner-label-header {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.landing__partner-label-desc {
  text-align: start;
  width: 75%;
}

.landing__open-label-desc {
  text-align: start;
  width: 70%;
}

.landing__partner-store-discuss {
  display: flex;
  width: 40rem;
  flex-direction: column;
  gap: 1rem;
  background-color: black;
  opacity: 0.8;
  position: absolute;
  right: 6.8%;
  bottom: 15%;
  padding: 1rem;
  padding-right: 3rem;
  z-index: 11;
  color: white;
  /*transform: skewX(-10deg);*/
  /*clip-path: polygon(100% 100%, 0 100%, 0 100%, 0% 0, 95% 0);*/
  animation: 0.4s slide-in-frame ease-in-out;
}

@keyframes slide-in-frame {
  0% {
    right: 0%;
    opacity: 0;
  }

  100% {
    right: 6.8%;
    opacity: 1;
  }
}

.landing__partner-store-discuss-left {
  display: flex;
  width: 40rem;
  flex-direction: column;
  gap: 1rem;
  background-color: black;
  opacity: 0.8;
  position: absolute;
  right: 6.8%;
  bottom: 15%;
  padding: 1rem;
  padding-right: 3rem;
  z-index: 11;
  color: white;
  /*transform: skewX(-10deg);*/
  /*clip-path: polygon(100% 100%, 0 100%, 0 100%, 0% 0, 95% 0);*/
  animation: 0.4s slide-in-frame-left ease-in-out;
}

@keyframes slide-in-frame-left {
  0% {
    left: 0%;
    opacity: 0;
  }

  100% {
    left: 6.8%;
    opacity: 1;
  }
}

.landing__first-col-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.landing__first-col-box {
  width: 2rem;
  height: 2rem;

  background-color: #ae29d9;
}

.landing__first-col-text {
  display: flex;
  /*transform: skewX(10deg);*/
}

.landing__awesome-arts-sec {
  height: 10vh;
  background-color: #232230;
}

.landing__awesome-arts-sec-inner {
  height: 100%;
  max-width: 100rem;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.landing__awesome-arts-btn {
  padding: 1rem 2rem;
  font-size: 0.7rem;
  background-color: #15151c;
  transform: skewX(-25deg);
  color: white;
  border: none;
  border-left: 1rem solid #7c187a;
  display: flex;
}

.landing__awesome-arts-btn-text {
  transform: skewX(25deg);
}

/* Gallery section */

.landing__gallery {
  width: 100vw;
  overflow-x: scroll;
  background-color: #16161e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.landing__gallery-first-move-container {
  width: 100%;

  display: inline-flex;
  /* height: 15vh; */
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  /* overflow: scroll; */
  /* padding: 2rem 0; */
}

.landing__gallery-second-move-container {
  width: 100%;
  display: inline-flex;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  /* transform: translate3d(5%, 0, 0); */
}

/* .landing__gallery-image-container{
    background-color: blue;
    height: 50%;
} */

.landing__gallery-image {
  width: 100%;
  height: 13vh;

  object-fit: cover;
  object-position: 100% 45%;
}

.landing__post-container {
  height: 40vh;
  display: flex;
  /* gap: 2rem; */
  position: relative;
  background-color: #16161e;
}

.landing__divider {
  position: absolute;
  height: 100%;
  top: 0%;
  left: 45%;
  width: 4rem;
  transform: skewX(-25deg);
  background-color: #16161e;
}

.landing__recent-blog-container {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #2e2e35;
  padding: 2rem 3rem;
}

.landing__recent-blog-header-cont {
  display: flex;
  justify-content: center;
}

.landing__recent-blog-header-btn {
  padding: 1rem 7rem;
  font-size: 0.7rem;
  background-color: #15151c;
  transform: skewX(-25deg);
  color: white;
  border: none;
  border-left: 1rem solid #7c187a;
  display: flex;
}

.landing__recent-blog-header-text {
  transform: skewX(25deg);
}

.landing__recent-post {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.landing__recent-post-title {
  color: #e9f2f4;
  font-size: 1.2rem;
}

.landing__recent-post-body {
  color: #71747a;
}

.landing__recent-prem-cont {
  color: #71747a;
  display: flex;
  gap: 3rem;
}

/* Community post starts here */
.landing__community-post-container {
  background-color: #2e2e35;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.landing__community-blog-container {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #2e2e35;
  padding: 2rem 3rem;
}

.landing__community-blog-header-cont {
  display: flex;
  justify-content: center;
}

.landing__community-blog-header-btn {
  padding: 1rem 7rem;
  font-size: 0.7rem;
  background-color: #15151c;
  transform: skewX(-25deg);
  color: white;
  border: none;
  border-left: 1rem solid #7c187a;
  display: flex;
}

.landing__community-blog-header-text {
  transform: skewX(25deg);
}

.landing__community-post {
  display: flex;
  gap: 0.4rem;
}

.landing__community-post-inner {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.landing__community-post-title {
  color: #e9f2f4;
  font-size: 1.2rem;
}

.landing__community-post-body {
  color: #71747a;
}

.landing__community-prem-cont {
  color: #71747a;
  display: flex;
  gap: 3rem;
}

.landing__community-img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  object-position: center;
}

/* Search section ends here */

.landing__footer {
  height: 40vh;
  background-color: #16161e;
  color: white;
}

.landing__footer-inner {
  display: flex;
  justify-content: space-between;
  padding: 3rem 1rem;
  max-width: 110rem;
  margin: 0 auto;
  gap: 2rem;
}

.landing__footer-socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.landing__socials-container {
  display: flex;
  gap: 1rem;
}

.landing__copyright-container {
  color: #65686e;
}

.landing__footer-resources {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.landing__footer-resources-header {
  color: white;
  font-size: 1.3rem;
}
.landing__footer-resources a {
  color: #65686e;
}
.landing__footer-resource-list {
  cursor: pointer;
}
.landing__footer-resources-list-cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #65686e;
}

.landing__footer-news-letter {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.landing__footer-news-letter-info {
  color: #65686e;
}

.landing__news-letter-form {
  display: flex;
}

.landing__news-letter-form-group {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: skewX(-25deg);
  border: 0.4rem solid #2b2b38;
  background-color: #15151c;
}

.landing__news-letter-form-control {
  padding: 1rem 1rem;
  background-color: transparent;
  transform: skewX(25deg);
  border: none;
  color: #ffffff;
}

.landing__news-letter-form-control::placeholder {
  color: #3e3e4f;
}

.landing__news-letter-form-control:focus {
  outline: none;
}

.landing__news-letter-btn,
.landing__news-letter-btn-2 {
  background-color: #38383e;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  /* height: 2.5rem; */
  border: none;
  position: relative;
  /* top: 10%;
    right: 1.2%; */
  cursor: pointer;
}

/* .landing__news-letter-btn-2 {

    right: .5% !important;
    top: 3.5%;
} */

.landing__news-letter-btn:active,
.landing__news-letter-btn-2:active {
  transform: scale(1.05);
}

.landing__news-letter-btn-text {
  transform: skewX(25deg);
}

.landing__card-image-box {
  position: relative;
}

.landing__card-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  background-color: rgba(58, 58, 58, 0.7);
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.landing__card-overlay span {
  font-weight: 700;
  font-size: 1.3rem;
  font-style: italic;
  color: whitesmoke;
  text-align: center;
}

.privacy_policy_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  width: 60%;
  margin: 1rem auto;
  padding: 4rem 10rem;
}
.privacy_policy-title {
  font-size: 2rem;
  font-weight: 900;
  text-align: start;
}
.privacy_policy-body {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 2rem;
}
.privacy_policy-section-title {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
}

.privacy_policy-outline {
  display: inline;
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 0.4rem;
}
.privacy_policy-sub-section {
  width: 90%;
  margin: 1rem auto;
}
.privacy_policy-bullet {
  font-size: 2.5rem;
  font-weight: 800;
  margin-right: 0.3rem;
  height: fit-content;
  line-height: 100%;
}
.privacy_policy-contact-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.support-contact {
  font-style: italic;
  color: rgb(255, 136, 0);
}
.results-container {
  min-height: 6ovh;
}
.nav-cart {
  position: relative;
}
.cart-number-alert {
  font-size: 0.7rem;
  color: #ffffff;
  position: absolute;
  top: -12px;
  right: -6px;
  background-color: #7c187a;
  padding: 4px;
  border-radius: 50%;
}

.product {
  padding: 2rem 4rem;
}

.product h2 {
  color: #e8e8e8;
  margin-bottom: 3rem;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}

.product__details {
  display: flex;
  border-top: 1px solid #3e3e4f;
  padding-top: 1rem;
  gap: 3rem;
}
.details__right {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 4rem;
}

.details__heading {
  text-transform: capitalize;
  color: #e8e8e8;
  font-size: 1.5rem;
}

.details__imgName {
  display: flex;
  color: #7e8993;
  font-weight: 500;
  gap: 5px;
  align-items: center;
}

.details__imgName .userName {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin-right: 0.3rem;
}

.details__btn {
  display: flex;
  align-items: flex-end;
  outline: 1px solid #fff;
  outline-offset: -3px;
  gap: 5px;
  padding: 0.5rem 1rem;
  transform: skewX(-25deg);
  justify-content: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 1rem;
}

.details__btn span {
  transform: skewX(25deg);
}
.details__btn.message {
  background-color: #a0269e;
}

.details__btn.delete {
  background-color: #f73b3b;
}
.details__description {
  text-transform: capitalize;
  color: #e8e8e8;
  font-size: 1.2rem;
  font-weight: 500;
}
.details__paragraph {
  text-transform: capitalize;
  color: #e8e8e8;
  margin-top: 1rem;
}

.details__files {
  display: inline-flex;
  gap: 0.4rem;
  align-items: center;
  margin-left: 2rem;
  background-color: rgb(255, 151, 0);
  padding: 0.4rem 1rem;
  font-weight: 600;
  transform: skewX(-25deg);
  cursor: pointer;
}

.details__files span {
  transform: skewX(25deg);
}

.details__fileItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 1rem;
}

.details__fileItems a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.6rem;
  transform: skewX(-25deg);
  background-color: rgba(56, 55, 74, 0.6);
}
.details__fileItems a span {
  transform: skewX(25deg);
  margin-right: 0.2rem;
  text-transform: capitalize;
  color: #6d6d91;
  font-weight: 600;
}
.details__fileItems a .size {
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 0.5rem;
  letter-spacing: 1px;
}
.details__fileItems a .download {
  margin-left: auto;
}
.no-wrap {
  white-space: nowrap;
  font-size: 12px;
}
.capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  .product {
    padding: 2rem;
  }
  .product__details {
    flex-direction: column;
    border-top: 1px solid #3e3e4f;
    padding-top: 1rem;
    gap: 3rem;
  }
}
