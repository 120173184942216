@import url("https://fonts.cdnfonts.com/css/trebuchet-ms-2");
@import url("https://fonts.cdnfonts.com/css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 92.5%;
}

body {
  background-color: #232230;
  overflow-x: hidden !important;
  overflow-y: auto;
  font-family: "Trebuchet MS", sans-serif;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Quicksilver";
  src: url("/src/fonts/Quicksilver.ttf");
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}
button:disabled {
  cursor: not-allowed;
}
/* .experiment-page{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
} */
::-webkit-scrollbar {
  width: 3px;
  height: 9px;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
  background-color: #232230;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #3e3e4f;
}

progress {
  width: 100%; /* Set the width to 100% of the parent container */
  height: 15px; /* Set the height to 5px */
  appearance: none;
  margin-bottom: 2px;
  border-radius: 15px; /* Remove the default browser styles */
}

/* Style the progress bar */
progress::-webkit-progress-value {
  background-color: purple; /* Set the color of the progress bar */
}

.toast__message {
  background-color: #15151c;
  color: #fafafa;
}
@media screen and (min-width: "1200px") {
  html {
    font-size: 32%;
  }
}
